import { Rule, ValidOperator, Condition, FoundUserActions } from './UserActionsEvaluatorTypes';

/**
 * @example
 * // Example usage
 * const rules = [
 *   {
 *     condition: {
 *       OR: [
 *         { AND: ["DemanderSiCaVa", "Saluer"] },
 *         "Accueillir"
 *       ]
 *     },
 *     result: "AccueillirEnEntretien"
 *   },
 *   {
 *     condition: {
 *       NOT: "Rassurer"
 *     },
 *     result: ["NePasRassurer", "Culpabilisation"]
 *   }
 * ];
 *
 * // in SmartBranchingDecision class:
 * const detectedUserActionIDs = ["DemanderSiCaVa", "Saluer"];
 * const evaluator = new UserActionsEvaluator(rules);
 * evaluator.applyRules(detectedUserActionIDs); // Outputs: ['AccueillirEnEntretien', 'NePasRassurer', "Culpabilisation"]
 */
export default class UserActionsEvaluator {
  private rules: Rule[];
  private readonly validOperators: ValidOperator[];

  /**
   * Creates an instance of UserActionsEvaluator.
   * @param {Array} rules - An array of rules with their corresponding result IDs.
   */
  constructor(rules: Rule[]) {
    this.rules = rules;
    this.validOperators = Object.values(ValidOperator);
    this.validateRules(rules);
  }

  /**
   * Validates the array of rules ensuring each is properly formatted and logically consistent.
   * @param {Array} rules - An array of rules to validate.
   * @throws Will throw an error if the rules argument is not an array or if any rule object is improperly formatted.
   */
  private validateRules(rules: Rule[]): void {
    if (!Array.isArray(rules)) {
      throw new Error('UserActionsEvaluator.validateRules: Rules should be provided as an array.');
    }

    rules.forEach((rule, index) => {
      if (!rule.condition || typeof rule.condition !== 'object' || !rule.result) {
        throw new Error(
          `UserActionsEvaluator.validateRules: Rule format error at index ${index}: Each rule must be an object with 'condition' and 'result' properties.`
        );
      }
      this.validateConditionStructure(rule.condition);
    });
  }

  /**
   * Recursively validates the structure of a single condition.
   * @param {Object|string} condition - The condition to validate.
   * @throws Will throw an error if any condition component (operator or operands) is invalid.
   */
  private validateConditionStructure(condition: Condition): void {
    if (typeof condition === 'string') {
      if (!condition.trim()) {
        throw new Error(
          'UserActionsEvaluator.validateCondtionStucture: Condition contains an empty userActionID.'
        );
      }
      return;
    }

    const operator = Object.keys(condition)[0];
    if (!this.validOperators.includes(operator as ValidOperator)) {
      throw new Error(
        `UserActionsEvaluator.validateCondtionStucture: Invalid operator: ${operator}. Valid operators are ${this.validOperators.join(
          ', '
        )}.`
      );
    }

    const operands = condition[operator];
    if (operator === ValidOperator.NOT) {
      if (
        typeof operands === 'string' ||
        (typeof operands === 'object' && !Array.isArray(operands))
      ) {
        this.validateConditionStructure(operands);
      } else {
        throw new Error(
          `UserActionsEvaluator.validateCondtionStucture: Operand for operator NOT should be either a string or a condition object.`
        );
      }
    } else {
      if (!Array.isArray(operands)) {
        throw new Error(
          `UserActionsEvaluator.validateCondtionStucture: Operands for operator ${operator} should be an array.`
        );
      }
      operands.forEach((subCondition) => this.validateConditionStructure(subCondition));
    }
  }

  /**
   * Evaluates a condition based on a list of userActionIDs and collects the ones that contributed to a true evaluation.
   * @param {Object|string} condition - The rule to evaluate.
   * @param {Array<string>} userActionIDs - An array of userActionIDs to test against the rule.
   * @param {Array<string>} contributingActions - An array to collect userActionIDs that contribute to a true condition.
   * @returns {boolean} - Returns true if the condition evaluates to true, otherwise false.
   * @throws Will throw an error if it encounters an unsupported operator.
   */
  private evaluateCondition(
    condition: Condition,
    userActionIDs: string[],
    contributingActions: string[] = []
  ): boolean {
    if (typeof condition === 'string') {
      const result = userActionIDs.includes(condition);
      if (result) {
        contributingActions.push(condition); // Capture the action that evaluates to true
      }
      return result;
    }

    const operator = Object.keys(condition)[0];
    const operands = condition[operator];

    switch (operator as ValidOperator) {
      case ValidOperator.AND:
        if (!operands || !Array.isArray(operands)) {
          throw new Error('AND operator requires an array of conditions');
        }
        return operands.every((subCondition) =>
          this.evaluateCondition(subCondition, userActionIDs, contributingActions)
        );

      case ValidOperator.OR: {
        if (!operands || !Array.isArray(operands)) {
          throw new Error('OR operator requires an array of conditions');
        }
        const subResults = operands.map((subCondition: Condition) => {
          const tempContributions: string[] = [];
          const result = this.evaluateCondition(subCondition, userActionIDs, tempContributions);
          if (result) {
            contributingActions.push(...tempContributions); // Only push if true
          }
          return result;
        });
        return subResults.some((result) => result);
      }

      case ValidOperator.NOT:
        if (!operands || Array.isArray(operands)) {
          throw new Error('NOT operator requires a single condition');
        }
        return !this.evaluateCondition(operands, userActionIDs, contributingActions);

      default:
        throw new Error(
          `UserActionsEvaluator.evaluateCondition: Unsupported operator: ${operator}`
        );
    }
  }

  /**
   * Applies all stored rules to a list of userActionIDs and returns the associated result IDs
   * and the contributing userActionIDs that triggered them.
   * @param {Array<string>} userActionIDs - An array of userActionIDs to test the rules against.
   * @param {Object} userActionTextMap - A map of userActionID to the associated text segment.
   * @returns {Object} - An object containing the result IDs and contributing userActions along with their text segments.
   */
  public getUserActionIDsFromRules(userActionIDs: string[]): FoundUserActions {
    let foundUserActions: FoundUserActions = {};

    this.rules.forEach((ruleObj) => {
      let tempContributions: string[] = [];

      // Evaluate the condition and collect contributing actions
      if (this.evaluateCondition(ruleObj.condition, userActionIDs, tempContributions)) {
        const ruleResults = this.getRuleResult(ruleObj);
        ruleResults.forEach((result) => {
          foundUserActions[result] = {
            contributingActionIDs: tempContributions
          };
        });
      }
    });

    return foundUserActions;
  }

  /**
   * Returns the result IDs from a rule object.
   * @param {Object} rule - The rule object to extract the result IDs from.
   * @returns {Array} - An array of result IDs.
   */
  private getRuleResult(rule: Rule): string[] {
    return Array.isArray(rule.result) ? rule.result : [rule.result];
  }
}
