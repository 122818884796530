import AbstractSolver from '../AbstractSolver';
import { STOP_TYPES, USER_ACTION_TAGS, FEEDBACK_EVALUATIONS } from '../constants';

const BRONZE_TROPHY_ID = 'BRONZE';
const SILVER_TROPHY_ID = 'SILVER';
const GOLD_TROPHY_ID = 'GOLD';
const PLATINUM_TROPHY_ID = 'PLATINUM';
const DIAMOND_TROPHY_ID = 'DIAMOND';
const LIMIT_CASE_TROPHY_ID = 'LIMIT_CASE';

export default class TrophiesSolver extends AbstractSolver {
  TrophiesEvents = [];
  UserActionFeedbacksEvents = [];
  StopEvent = null;

  /**
   * Determines the highest unlocked trophy based on the user's performance.
   * @returns {string} The ID of the highest unlocked trophy.
   */
  SolveUnlockedTrophy() {
    this.TrophiesEvents = this.Graph.History.GetTrophiesEvents();
    this.UserActionFeedbacksEvents = this.Graph.History.GetUserActionsFeedbacks();
    this.StopEvent = this.Graph.History.GetStopEvent();

    // Define trophy checks in order of precedence,
    // declaring for each trophy:
    // the check function and the trophy ID to return
    const trophyChecks = [
      {
        check: this.IsLimitCaseTrophyUnlocked,
        trophyID: LIMIT_CASE_TROPHY_ID
      },
      {
        check: this.IsPlatinumTrophyUnlocked,
        trophyID: PLATINUM_TROPHY_ID
      },
      { check: this.IsGoldTrophyUnlocked, trophyID: GOLD_TROPHY_ID },
      { check: this.IsSilverTrophyUnlocked, trophyID: SILVER_TROPHY_ID },
      { check: this.IsBronzeTrophyUnlocked, trophyID: BRONZE_TROPHY_ID }
    ];

    // Check each trophy condition and return the first that is true
    for (const { check, trophyID } of trophyChecks) {
      if (check.apply(this)) {
        return trophyID;
      }
    }
  }

  /**
   * Checks if the Bronze trophy has been unlocked.
   * @returns {boolean} True if Bronze trophy is unlocked, false otherwise.
   */
  IsBronzeTrophyUnlocked() {
    // temporary hack to always unlock the bronze trophy if all others are not unlocked
    return true;
  }

  /**
   * Checks if the Silver trophy has been unlocked.
   * @param {Array} iTrophiesEvents - Array of trophy events.
   * @returns {boolean} True if Silver trophy is unlocked, false otherwise.
   */
  IsSilverTrophyUnlocked() {
    return this.TrophiesEvents.some((event) => event.Content.TrophyID === SILVER_TROPHY_ID);
  }

  /**
   * Checks if the Gold trophy has been unlocked.
   * @returns {boolean} True if Gold trophy is unlocked, false otherwise.
   */
  IsGoldTrophyUnlocked() {
    // Gold trophy is unlocked if the exercise was completed successfully
    if (!this.StopEvent || this.StopEvent.Content.StopType === STOP_TYPES.FAILED) {
      return false;
    }

    return true;
  }

  /**
   * Checks if the Platinum trophy has been unlocked.
   * @returns {boolean} True if Platinum trophy is unlocked, false otherwise.
   */
  IsPlatinumTrophyUnlocked() {
    if (!this.StopEvent || this.StopEvent.Content.StopType === STOP_TYPES.FAILED) {
      return false;
    }

    const actCompletionEvents = this.Graph.History.GetActCompletionEvents();

    // Platinum trophy requires all acts to be evaluated as GOOD
    for (const actCompletionEvent of actCompletionEvents) {
      if (actCompletionEvent.Content.Evaluation !== FEEDBACK_EVALUATIONS.GOOD) {
        return false;
      }
    }

    return true;
  }

  /**
   * Checks if the Limit Case trophy has been unlocked.
   * @returns {boolean} True if Limit Case trophy is unlocked, false otherwise.
   */
  IsLimitCaseTrophyUnlocked() {
    const limitCaseActions = this.GetFeedbacksByTagFromUserActionsEvents(
      USER_ACTION_TAGS.LIMIT_CASE
    );

    // Limit Case trophy is unlocked if 2 limit case actions were performed
    return limitCaseActions.length >= 2 ? true : false;
  }

  /**
   * Retrieves user action feedbacks with a specific tag from the given events.
   * @param {string} iTag - The tag to filter by.
   * @returns {Array} Array of user action feedbacks with the specified tag.
   */
  GetFeedbacksByTagFromUserActionsEvents(iTag) {
    const userActionFeedbacks = [];

    for (const uafEvent of this.UserActionFeedbacksEvents) {
      const userActionFeedback = this.Graph.GetFullUserActionFeedbackData(
        uafEvent.Content.UserActionFeedbackID,
        uafEvent.Content.NodeID
      );

      if (userActionFeedback.Tags.includes(iTag)) {
        userActionFeedbacks.push(userActionFeedback);
      }
    }

    return userActionFeedbacks;
  }
}
