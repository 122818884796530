import Card from '@UIKit/Card/Card';
import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Translation } from '@/components/Utilities/Translation';
import { fixBody, unfixBody } from '@/helpers/fix-body';

type ReasonType =
  | 'focus'
  | 'pausedDueToNoAudio'
  | 'pausedDueToNoAudioAfter'
  | 'timeout-pause'
  | 'pause-due-device-changed'
  | 'sttFailed'
  | 'pingNotHealthy'
  | string
  | boolean;

const PauseMenu = ({
  reason,
  pauseTxt = '',
  unpluggedDevice = '',
  fancyFunctions
}: {
  reason: ReasonType;
  pauseTxt: string;
  unpluggedDevice: string;
  fancyFunctions: {
    resume: () => void;
    stop: () => void;
    menu: () => void;
    restart: (step?: string) => void;
  };
}) => {
  useEffect(() => {
    fixBody();
    window.zE('webWidget', 'show');
    return () => {
      unfixBody();
      window.zE('webWidget', 'hide');
    };
  });

  let modalContent = <></>;

  switch (reason) {
    case 'focus':
      modalContent = (
        <>
          <h2 className="title">
            <Translation keyName="main.paused_exercise_title">L'exercice est en pause</Translation>
          </h2>
          <p className="mt-6 text-base">
            <Translation keyName="main.automatic_pause_expl">
              Celui-ci a été mis en pause automatiquement afin que vous ne perdiez pas votre
              progression
            </Translation>
          </p>
          <p className="mt-4 text-base">
            <Translation keyName="main.click_restart">
              En cliquant sur "Reprendre", votre simulation reprendra où vous vous en étiez arrêté
            </Translation>
          </p>
          <p className="mt-4 text-base">
            <Translation keyName="main.stop_exercise">
              Si vous éprouvez des difficultés excessives, vous pouvez cliquer sur le bouton
              "Arrêter". Vous passerez directement à la suite de la formation
            </Translation>
          </p>

          <p className="mt-6 flex space-x-6">
            <button className="cta cta--accent" onClick={fancyFunctions.resume}>
              <Translation keyName="main.resume">Reprendre</Translation>
            </button>
            <button className="cta cta--white" onClick={fancyFunctions.stop}>
              <Translation keyName="main.stop">Arrêter</Translation>
            </button>
            <button className="cta" onClick={fancyFunctions.menu}>
              <Translation keyName="main.menu">Menu</Translation>
            </button>
          </p>
        </>
      );
      break;
    case 'frontendTimeout':
      modalContent = (
        <>
          <h2 className="title">
            <Translation keyName="pause_menu.frontend_timeout.title"></Translation>
          </h2>

          <p className="mt-6 text-base">
            <Translation keyName="pause_menu.frontend_timeout.text"></Translation>
          </p>

          <p className="mt-6 flex space-x-6">
            <button className="cta cta--accent" onClick={fancyFunctions.stop}>
              <Translation keyName="general.quit">Quitter</Translation>
            </button>
            <button className="cta cta--white" onClick={fancyFunctions.resume}>
              <Translation keyName="general.continue">Continuer</Translation>
            </button>
          </p>
        </>
      );
      break;
    case 'sttFailed':
      modalContent = (
        <>
          <h2 className="title">
            <Translation keyName="pause_menu.stt_failed.title"></Translation>
          </h2>

          <p className="mt-6 text-base">
            <Translation keyName="pause_menu.stt_failed.text"></Translation>
          </p>

          <p className="mt-6 flex space-x-6">
            <button className="cta cta--accent" onClick={() => fancyFunctions.restart()}>
              <Translation keyName="general.restart">Recommencer</Translation>
            </button>
            <button className="cta cta--white" onClick={fancyFunctions.stop}>
              <Translation keyName="general.quit">Arrêter</Translation>
            </button>
          </p>
        </>
      );
      break;
    case 'pingNotHealthy':
      modalContent = (
        <>
          <h2 className="title">
            <Translation keyName="pause_menu.ping_not_healthy.title"></Translation>
          </h2>

          <p className="mt-6 text-base">
            <Translation keyName="pause_menu.ping_not_healthy.text"></Translation>
          </p>

          <p className="mt-6 flex space-x-6">
            <button className="cta cta--accent" onClick={() => fancyFunctions.restart()}>
              <Translation keyName="general.restart">Recommencer</Translation>
            </button>
            <button className="cta cta--white" onClick={fancyFunctions.stop}>
              <Translation keyName="general.quit">Arrêter</Translation>
            </button>
          </p>
        </>
      );
      break;
    case 'pausedDueToNoAudioAfter':
      modalContent = (
        <>
          <h2 className="title">
            <Translation keyName="main.paused_exercise_title">L'exercice est en pause</Translation>
          </h2>

          <p className="mt-6 flex space-x-6">
            <button className="cta cta--accent" onClick={fancyFunctions.resume}>
              <Translation keyName="main.resume">Reprendre</Translation>
            </button>
            <button className="cta cta--white" onClick={fancyFunctions.stop}>
              <Translation keyName="main.stop">Arrêter</Translation>
            </button>
          </p>
        </>
      );
      break;
    case 'timeout-pause':
      modalContent = (
        <>
          <h2 className="title">
            <Translation keyName="main.disconnected">Vous avez été déconnecté.e</Translation>
          </h2>

          <p className="mt-6 text-base">
            <Translation keyName="main.disconnected_inactivity">
              Vous avez été déconnecté.e de l'exercice suite à une période d'inactivité
            </Translation>
          </p>
          <p className="mt-4 text-base">
            <Translation keyName="main.click_restart">
              Cliquez sur "Recommencer" pour relancer l'exercice
            </Translation>
          </p>

          <button className="cta cta--accent mt-6" onClick={() => fancyFunctions.restart()}>
            <Translation keyName="general.restart">Recommencer</Translation>
          </button>
        </>
      );
      break;
    case 'pause-due-device-changed':
      modalContent = (
        <>
          <h2 className="title">
            <Translation keyName="general.disconnection">Débranchement</Translation>
          </h2>

          <p className="mt-6 text-base">
            <Translation keyName="main.detected_disconnect">
              Nous avons détecté le débranchement de votre
            </Translation>
            {unpluggedDevice}.
          </p>
          <p className="mt-4 text-base">
            <Translation keyName="main.click_restart">
              Cliquez sur "Recommencer" pour relancer l'exercice
            </Translation>
          </p>

          <button className="cta cta--accent mt-6" onClick={() => fancyFunctions.restart()}>
            <Translation keyName="general.restart">Recommencer</Translation>
          </button>
        </>
      );
      break;
    default:
      modalContent = (
        <>
          <h2 className="title">
            <Translation keyName="main.paused_exercise_title">L'exercice est en pause</Translation>
          </h2>
          <p className="mt-6 text-base">
            <Translation keyName="main.resume_exercise">
              En cliquant sur "Reprendre", votre simulation reprendra où vous vous en étiez arrêté
            </Translation>
          </p>
          <p className="mt-4 text-base">
            <Translation keyName="main.stop_exercise">
              Si vous éprouvez des difficultés excessives, vous pouvez cliquer sur le bouton
              "Arrêter". Vous passerez directement à la suite de la formation
            </Translation>
          </p>

          <p className="mt-6 flex space-x-6">
            <button className="cta cta--accent" onClick={fancyFunctions.resume}>
              <Translation keyName="main.resume">Reprendre</Translation>
            </button>
            <button className="cta cta--white" onClick={fancyFunctions.stop}>
              <Translation keyName="main.stop">Arrêter</Translation>
            </button>
            <button className="cta" onClick={fancyFunctions.menu}>
              <Translation keyName="main.menu">Menu</Translation>
            </button>
          </p>
        </>
      );
  }

  const Layout = ({ ...props }: PropsWithChildren<{}>) => (
    <section
      data-testid={`pause-menu-modal-${reason}`}
      className="fixed left-0 top-12 z-40 flex min-h-[calc(100vh-theme(spacing.12))] w-full flex-col justify-center bg-white/50 backdrop-blur-lg">
      <Card className="centered-row mt-8 [&.centered-row]:max-w-half-row">{props.children}</Card>
    </section>
  );

  return ReactDOM.createPortal(
    <Layout>{modalContent}</Layout>,
    document.getElementById('menu-modal-root') as HTMLElement
  );
};

export default PauseMenu;
