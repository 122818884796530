import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from './Shared/NodePort';
import { GraphEventTypes } from '../GraphNotifier/GraphEvent';
import Path from './Shared/Path';
import { HistoryEventTypes } from '@/AppClasses/ExerciseScenario/ExerciseSessionHistory';

export default class Act extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  ActName = '';
  ActNumber = 0;
  Paths = [];

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.ActName = iProperties.ActName;
    this.ActNumber = iProperties.ActNumber;

    if (iProperties.Paths) {
      this.InitPaths(iProperties.Paths);
    }
  }

  InitPaths(iPaths) {
    iPaths.forEach((path) => {
      const newPath = new Path(path.ID);
      this.Paths.push(newPath);

      this[newPath.GetInputPortName()] = new NodePort(newPath.GetInputPortName(), 'input', this);
      this[newPath.GetOutputPortName()] = new NodePort(newPath.GetOutputPortName(), 'output', this);
    });
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    this.Graph.SetCurrentActNode(this);

    if (iIsRewindMode) {
      return;
    }

    this.Graph.Notifier.notify({
      type: GraphEventTypes.ActCompletion,
      content: {
        id: this.ActName,
        displayedName: this.ActName,
        inProgress: true
      }
    });

    this.ActivateOutput(iActivationLink);
  }

  ActivateOutput(iActivationLink) {
    this.SetActive(false);

    let outputPort = this.Output; // Default to main output

    if (iActivationLink) {
      const chosenPath = this.GetPathFromInput(iActivationLink.Target.Port);
      if (chosenPath) {
        outputPort = this[chosenPath.GetOutputPortName()];
      }
    }

    log.debug(`${this.GetIdentity()} activating output ${outputPort.GetIdentity()}`);
    outputPort.Activate();
  }

  GetPathFromInput(iInputPort) {
    return this.Paths.find((path) => path.GetInputPortName() === iInputPort.Name);
  }

  PrintParameters() {
    //log.debug("Act: ActName = " + this.ActName + ".");
  }

  SolvePreviousSceneEvaluation() {
    const isSceneAlreadyEvaluated = this.Graph.History.GetOneEventBy({
      EventType: HistoryEventTypes.SCENE_EVALUATION,
      'Content.EvaluatedSceneNodeID': this.Graph.GetCurrentSceneNodeID()
    });

    if (isSceneAlreadyEvaluated) {
      return;
    }

    if (this.Graph.LastBranchingDecisionNode) {
      const previousSceneEvaluation = this.Graph.SceneEvaluationSolver.SolveSceneEvaluation();
      this.Graph.History.AddSceneEvaluationEvent(
        this.Graph.GetCurrentSceneNodeID(),
        previousSceneEvaluation.evaluation,
        previousSceneEvaluation.userActionsFeedbacks
      );
    }
  }

  OnActEvaluation() {
    // solve previous last scene before entering the act
    this.SolvePreviousSceneEvaluation();

    const actEvaluation = this.Graph.ActEvaluationSolver.SolveActEvaluation();

    this.Graph.Notifier.notify({
      type: GraphEventTypes.ActCompletion,
      content: {
        id: this.ActName,
        displayedName: this.ActName,
        evaluation: actEvaluation.evaluation,
        inProgress: false
      }
    });

    this.Graph.History.AddActCompletionEvent(
      this.ID,
      this.ActName,
      actEvaluation.evaluation,
      actEvaluation.userActionsFeedbacks
    );
  }
}
