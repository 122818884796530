import log from 'loglevel';
import * as Sentry from '@sentry/browser';
import Utils from '../../../Utils/Utils';

export default class FeedbackSolver {
  constructor(iGraph) {
    this.Graph = iGraph;
  }

  GetPedagogicalEndToDisplay() {
    // Get available Pedagogical ends from Exercice graph
    let availablePedagogicalEnds = this.Graph.GetAvailablePedagogicalEnds();

    // For each pedagogical end we check the logic activation
    for (const pedagogicalEndToDisplay of Object.values(availablePedagogicalEnds)) {
      // Get the logic string from the graph
      let logicRuleString = pedagogicalEndToDisplay.DetectionRule;
      if (logicRuleString === '') {
        log.error(
          "FeedbackSolver.GetPedagogicalEndToDisplay: End ID '" +
            pedagogicalEndToDisplay.ID +
            "' has no detection rule!"
        );
        continue;
      }

      // Replace occurrences of CountNarrativeEnd(...) with the result of this.Graph.CountNarrativeEnd(...)
      const countNarrativeEndRegex = /CountNarrativeEnd\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(countNarrativeEndRegex, (match, narrativeEnd) => {
        // Call this.Graph.CountActions(...) and return the result
        return this.Graph.CountNarrativeEnd(narrativeEnd);
      });

      // Replace occurrences of CountActionsByID(...) with the result of this.Graph.CountActionsByID(...)
      const countUserActionsByIDRegex = /CountUserActionsByID\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(
        countUserActionsByIDRegex,
        (match, userActionID) => {
          //log.debug("============== Logic rule string : ",logicRuleString);
          // Call this.Graph.CountActionsByID(...) and return the result
          return this.Graph.CountUserActionsByID(userActionID);
        }
      );

      // Replace occurrences of CountUserActionsByType(...) with the result of this.Graph.CountUserActionsByType(...)
      const countUserActionsByTypeRegex = /CountUserActionsByType\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(
        countUserActionsByTypeRegex,
        (match, userActionTypes) => {
          //log.debug("============== Logic rule string : ",logicRuleString);
          // Call this.Graph.CountUserActionsByType(...) and return the result
          return this.Graph.CountUserActionsByType(userActionTypes);
        }
      );
      //log.debug("============== Logic rule string : ",logicRuleString);

      // Execute the logical string
      const logicRuleResult = Utils.EvalMathematicalFunction(logicRuleString);

      // Return the first valid pedalogical end
      if (!logicRuleResult.error && logicRuleResult.result === true) {
        log.debug(
          "FeedbackSolver.GetPedagogicalEndToDisplay: Pedagogical end '" +
            pedagogicalEndToDisplay.ID +
            "' chosen, with DetectionRule = '" +
            pedagogicalEndToDisplay.DetectionRule +
            "' and resolved logicRuleString = '" +
            logicRuleString +
            "'."
        );
        return pedagogicalEndToDisplay;
      } else if (logicRuleResult.error) {
        const errorMessage =
          "FeedbackSolver.GetPedagogicalEndToDisplay: Pedagogical end '" +
          pedagogicalEndToDisplay.ID +
          "' has an invalid DetectionRule = '" +
          pedagogicalEndToDisplay.DetectionRule +
          "' and resolved logicRuleString = '" +
          logicRuleString +
          "'. Error: " +
          logicRuleResult.error;
        Sentry.captureMessage(errorMessage);
        log.error(errorMessage);
      }
    }
  }

  GetPedagogicalRecommendationsToDisplay() {
    let pedagogicalsRecommendationsToDisplay = [];

    // Get available Pedagogical Recommendations from Exercice graph
    let availablePedagogicalRecommendations = this.Graph.GetAvailablePedagogicalRecommendations();

    // For each pedagogical Recommendation we check the logic activation
    for (const pedagogicalRecommendationToDisplay of Object.values(
      availablePedagogicalRecommendations
    )) {
      // Get the logic string from the graph
      let logicRuleString = pedagogicalRecommendationToDisplay.DetectionRule;
      if (logicRuleString === '') {
        log.error(
          "FeedbackSolver.GetPedagogicalRecommendationsToDisplay: Recommendation ID '" +
            pedagogicalRecommendationToDisplay.ID +
            "' has no detection rule!"
        );
        continue;
      }

      // Replace occurrences of CountNarrativeEnd(...) with the result of this.Graph.CountNarrativeEnd(...)
      const countNarrativeEndRegex = /CountNarrativeEnd\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(countNarrativeEndRegex, (match, narrativeEnd) => {
        // Call this.Graph.CountNarrativeEnd(...) and return the result
        return this.Graph.CountNarrativeEnd(narrativeEnd);
      });

      // Replace occurrences of CountActionsByID(...) with the result of this.Graph.CountActionsByID(...)
      const countUserActionsByIDRegex = /CountUserActionsByID\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(countUserActionsByIDRegex, () => {
        // Call this.Graph.CountActionsByID(...) and return the result
        return this.Graph.userActionID;
      });

      // Replace occurrences of CountUserActionsByType(...) with the result of this.Graph.CountUserActionsByType(...)
      const countUserActionsByTypeRegex = /CountUserActionsByType\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(countUserActionsByTypeRegex, () => {
        // Call this.Graph.CountUserActionsByType(...) and return the result
        return this.Graph.userActionTypes;
      });

      // Exectute the logical string
      const logicRuleResult = Utils.EvalMathematicalFunction(logicRuleString);

      // Add the pedalogical Recommendation if valid
      if (!logicRuleResult.error && logicRuleResult.result === true) {
        log.debug(
          "FeedbackSolver.GetPedagogicalRecommendationsToDisplay: Recommendation '" +
            pedagogicalRecommendationToDisplay.ID +
            "' chosen, with DetectionRule = '" +
            pedagogicalRecommendationToDisplay.DetectionRule +
            "' and resolved logicRuleString = '" +
            logicRuleString +
            "'."
        );
        pedagogicalsRecommendationsToDisplay.push(pedagogicalRecommendationToDisplay);
      } else if (logicRuleResult.error) {
        const errorMessage =
          "FeedbackSolver.GetPedagogicalRecommendationsToDisplay: Recommendation '" +
          pedagogicalRecommendationToDisplay.ID +
          "' has an invalid DetectionRule = '" +
          pedagogicalRecommendationToDisplay.DetectionRule +
          "' and resolved logicRuleString = '" +
          logicRuleString +
          "'. Error: " +
          logicRuleResult.error;
        Sentry.captureMessage(errorMessage);
        log.error(errorMessage);
      }
    }

    return pedagogicalsRecommendationsToDisplay;
  }

  GetPedagogicalAdditionsToDisplay() {
    let pedagogicalsAdditionsToDisplay = [];

    // Get available Pedagogical additions from Exercice graph
    let availablePedagogicalAdditions = this.Graph.GetAvailablePedalogologicalAdditions();

    // For each pedagogical addition we check the logic activation
    for (const pedagogicalAdditionToDisplay of Object.values(availablePedagogicalAdditions)) {
      // Get the logic string from the graph
      let logicRuleString = pedagogicalAdditionToDisplay.DetectionRule;
      if (logicRuleString === '') {
        log.error(
          "FeedbackSolver.GetPedagogicalAdditionsToDisplay: Addition ID '" +
            pedagogicalAdditionToDisplay.ID +
            "' has no detection rule!"
        );
        continue;
      }

      // Replace occurrences of CountNarrativeEnd(...) with the result of this.Graph.CountNarrativeEnd(...)
      const countNarrativeEndRegex = /CountNarrativeEnd\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(countNarrativeEndRegex, (match, narrativeEnd) => {
        // Call this.Graph.CountActions(...) and return the result
        return this.Graph.CountNarrativeEnd(narrativeEnd);
      });

      // Replace occurrences of CountActionsByID(...) with the result of this.Graph.CountActionsByID(...)
      const countUserActionsByIDRegex = /CountUserActionsByID\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(
        countUserActionsByIDRegex,
        (match, userActionID) => {
          // Call this.Graph.CountActionsByID(...) and return the result
          return this.Graph.CountUserActionsByID(userActionID);
        }
      );
      //log.debug("============== Logic rule string : ",logicRuleString);

      // Replace occurrences of CountUserActionsByType(...) with the result of this.Graph.CountUserActionsByType(...)
      const countUserActionsByTypeRegex = /CountUserActionsByType\(\s*['"]([^'"]+)['"]\s*\)/g;
      logicRuleString = logicRuleString.replace(
        countUserActionsByTypeRegex,
        (match, userActionTypes) => {
          // Call this.Graph.CountUserActionsByType(...) and return the result
          return this.Graph.CountUserActionsByType(userActionTypes);
        }
      );

      // Exectute the logical string
      const logicRuleResult = Utils.EvalMathematicalFunction(logicRuleString);

      // Add the pedalogical addition if valid
      if (!logicRuleResult.error && logicRuleResult.result === true) {
        log.debug(
          "FeedbackSolver.GetPedagogicalAdditionsToDisplay: Addition '" +
            pedagogicalAdditionToDisplay.ID +
            "' chosen, with DetectionRule = '" +
            pedagogicalAdditionToDisplay.DetectionRule +
            "' and resolved logicRuleString = '" +
            logicRuleString +
            "'."
        );
        pedagogicalsAdditionsToDisplay.push(pedagogicalAdditionToDisplay);
      } else if (logicRuleResult.error) {
        const errorMessage =
          "FeedbackSolver.GetPedagogicalAdditionsToDisplay: Addition '" +
          pedagogicalAdditionToDisplay.ID +
          "' has an invalid DetectionRule = '" +
          pedagogicalAdditionToDisplay.DetectionRule +
          "' and resolved logicRuleString = '" +
          logicRuleString +
          "'. Error: " +
          logicRuleResult.error;
        Sentry.captureMessage(errorMessage);
        log.error(errorMessage);
      }
    }

    return pedagogicalsAdditionsToDisplay;
  }
}
