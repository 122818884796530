import React from 'react';
import Accordion from '@UIKit/Accordion/Accordion';
import Card from '@UIKit/Card/Card';
import defs from '@/assets/new-ui/defs.svg';
import { Translation } from '@/components/Utilities/Translation';
import ProgressBar from '@/components/ProgressBar/ProgressBar';

type BriefingSidebarProps = {
  cache: { totalProgression: number };
  onSpeechDetected: (text: string, session: any) => void;
  goToSettings: () => void;
  showZendesk: () => void;
};

export const BriefingSidebar = ({
  cache,
  onSpeechDetected,
  goToSettings,
  showZendesk
}: BriefingSidebarProps) => {
  return (
    <div className="relative z-1 mt-8 lg:fixed lg:left-0 lg:top-12 lg:mt-0 lg:flex lg:h-[calc(100vh-theme(spacing.12))] lg:w-1/4 lg:flex-col">
      <Card
        theme="BRAND"
        className="mx-4 rounded-b-none sm:mx-6 lg:mx-0 lg:flex lg:flex-1 lg:flex-col lg:rounded-l-none">
        {cache && cache.totalProgression < 100 ? (
          <div
            className="align-center flex size-full flex-col justify-center"
            data-testid="briefing-videos-loading-text">
            <h1 className="mb-6 text-center title-sm">
              <Translation keyName="briefing.loadingText">Chargement de l'expérience"</Translation>
            </h1>
            <ProgressBar progress={cache.totalProgression} />
          </div>
        ) : (
          <div className="align-center flex size-full flex-col justify-center">
            <svg
              width="32"
              height="54"
              viewBox="0 0 32 54"
              className="mx-auto fill-current"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false">
              <use href={`${defs}#micro`} />
            </svg>
            <p className="mt-6 text-center title" data-testid="briefing-mic-validation-ready-text">
              <Translation keyName="micValidation.readConfirmation">
                Vous avez pris connaissance des données de l'exercice ?
              </Translation>
            </p>
            <p className="mt-6 text-center title-sm">
              <Translation keyName="micValidation.ready">Quand vous êtes prêt.e, dites</Translation>
              "
              <span
                className="whitespace-nowrap text-accent"
                onClick={() => {
                  // ssssh it's a secret, don't tell anyone
                  if (process.env.REACT_APP_ENV === 'dev') {
                    onSpeechDetected("c'est parti", null);
                  }
                }}>
                <Translation keyName="micValidation.letsgo">C'est parti !</Translation>
              </span>
              "
            </p>
          </div>
        )}
      </Card>
      <Card
        theme="LIGHT_BRAND"
        className="mx-4 rounded-t-none text-white sm:mx-6 lg:mx-0 lg:rounded-l-none [&]:p-0">
        <Accordion id="help">
          <Accordion.CTALabel className="py-6 pl-6 pr-[calc(18px+theme(spacing.10))] after:right-6 lg:pl-12 lg:pr-[calc(18px+theme(spacing.16))] lg:after:right-12 [&_+_[role='region']]:px-6 lg:[&_+_[role='region']]:px-12">
            <span className="mr-2 inline-flex size-5 items-center justify-center rounded-full bg-current">
              <svg
                width="4"
                height="10"
                viewBox="0 0 4 10"
                className="fill-brand"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false">
                <use href={`${defs}#info`} />
              </svg>
            </span>
            <span className="text-sm font-semibold">
              <Translation keyName="briefing.help">Besoin d'aide ?</Translation>
            </span>
          </Accordion.CTALabel>

          <ol className="-mr-6 list-decimal text-sm">
            <li>
              <span className="flex items-center justify-between">
                <p>
                  <Translation keyName="briefing.micSetup">
                    Reconfigurez votre micro dans les paramètres.
                  </Translation>
                </p>
                <button
                  className="cta cta--without-hover-effect ml-auto flex items-center"
                  onClick={goToSettings}>
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    className="mr-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false">
                    <use href={`${defs}#settings`} />
                  </svg>
                  <Translation keyName="briefing.settings">Paramètres</Translation>
                </button>
              </span>
            </li>
            <li>
              <span className="flex items-center justify-between">
                <Translation keyName="briefing.contactSupport">Contactez l'assistance</Translation>
                <button
                  className="cta cta--without-hover-effect ml-auto flex items-center"
                  onClick={showZendesk}>
                  <span className="mr-2 flex size-5 items-center justify-center rounded-full bg-current">
                    <svg
                      width="4"
                      height="10"
                      viewBox="0 0 4 10"
                      className="fill-brand"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false">
                      <use href={`${defs}#info`} />
                    </svg>
                  </span>
                  Aide
                </button>
              </span>
            </li>
          </ol>
        </Accordion>
      </Card>
    </div>
  );
};
