import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import { Translation } from '../Utilities/Translation';
import Card from '@UIKit/Card/Card';
import React from 'react';

type CollaboratorCardProps = {
  pictureURL: string;
  description: string;
  difficulty: string;
};

export const CollaboratorCard = ({
  pictureURL,
  description,
  difficulty
}: CollaboratorCardProps) => {
  const computePictureURL = () => {
    return `${process.env.REACT_APP_BOTS_BUCKET_BASE_URL}${pictureURL}`;
  };

  return (
    <Card className="mt-6 p-8" areCustomStyles={false} dataTestid="exercise-briefing-collaborator">
      <Card.Border color="advice">
        <Card.Title color="advice">
          <h2 className="mt-2 title">
            <Translation keyName="briefing.collaboratorCardTitle">Votre collaborateur</Translation>
          </h2>
        </Card.Title>
        <div className="mt-2 flex items-center">
          <img src={computePictureURL()} alt="Collaborator" className="mr-4 w-[160px] rounded-lg" />
          <div className="flex">
            <div className="flex flex-col gap-2">
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(description)
                }}
              />
              <p className="pl-2">
                <Translation keyName="briefing.difficultyLevel">Niveau de difficulté :</Translation>{' '}
                <span className="font-bold">{difficulty}</span>
              </p>
            </div>
          </div>
        </div>
      </Card.Border>
    </Card>
  );
};
