import Toast, { OriginSideType, TranslateYType } from '@UIKit/Toasts/Toast';
import React, { useContext, useEffect, useState } from 'react';
import { EvaluationType } from '@/models/DetailedFeedbackModel';
//@ts-ignore
import { ExerciseContext } from '@/contexts/ExerciseContext';
export type ToastDataModel = {
  id: string;
  evaluation: EvaluationType;
  body: JSX.Element | string;
};

type ToastsProps = {
  className?: string;
  originSide?: OriginSideType;
  translateY?: TranslateYType;
};

const ToastsContainer = ({ className = '', originSide, translateY }: ToastsProps) => {
  const { userActionToasts, setUserActionToasts }: any = useContext(ExerciseContext);

  const removeToast = (id: string) => {
    setUserActionToasts((toasts: ToastDataModel[]) =>
      toasts.filter((toast: ToastDataModel) => toast.id !== id)
    );
  };

  return (
    <ul className={`${className} space-y-12`}>
      {userActionToasts.map((toast: ToastDataModel) => (
        <li key={toast.id}>
          <Toast
            id={toast.id}
            evaluation={toast.evaluation}
            originSide={originSide}
            translateY={translateY}
            onClose={() => removeToast(toast.id)}>
            {toast.body}
          </Toast>
        </li>
      ))}
    </ul>
  );
};

export default ToastsContainer;
