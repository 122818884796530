import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import log from 'loglevel';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { LinkContainer } from 'react-router-bootstrap';
import { PasswordValidationIndicator } from '@/components/Utilities/PasswordValidationIndicator';
import { Translation } from '@/components/Utilities/Translation';
import { ReactComponent as Practicio } from '@/assets/new-ui/practicio.svg';
const EditPassword = () => {
  const { t } = useTranslate();

  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [info, setInfo] = useState({ message: '' });
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [valided, setValided] = useState({
    number: false,
    special: false,
    length: false,
    min: false,
    maj: false,
    email: true,
    password: true,
    password2: true,
    entity: true
  });

  const submit = async () => {
    let check = validateForm();
    if (!check.valid) return;

    let res = await window.sdk.user().passwordEdit({
      verificationCode,
      password: newPassword,
      email
    });

    log.debug('res', res);

    if (res.state === 'fail') {
      log.debug('res', res);
      setStatus('fail');
      setInfo(res.info);
    } else {
      setStatus('success');
      setInfo(res.info);
      if (window.sdk.redirect_url) document.location.href = window.sdk.redirect_url;
      else document.location.href = '/';
    }
  };

  const validateForm = () => {
    let newState: { status: string; valid: boolean; info: { message: string } } = {
      status: '',
      valid: true,
      info: {
        message: ''
      }
    };

    if (!verificationCode && !email && !newPassword && !newPassword2) {
      newState.valid = false;
      return newState;
    }

    try {
      if (!verificationCode) {
        throw new Error(
          t('editPassword.code', 'Veuillez saisir votre code de vérification reçu par email')
        );
      }
      if (!email) {
        throw new Error(t('editPassword.curr_email', 'Veuillez saisir votre email actuel'));
      }
      if (!newPassword) {
        throw new Error(t('editPassword.new_pwd', 'Veuillez saisir votre nouveau mot de passe'));
      }
      if (!newPassword2) {
        throw new Error(
          t('editPassword.confirm_pwd', 'Veuillez confirmer votre nouveau mot de passe')
        );
      }
      if (newPassword !== newPassword2) {
        throw new Error(t('editPassword.dont_match', 'Veuillez verifier la confirmation'));
      }
    } catch (error: any) {
      newState.status = 'fail';
      newState.info.message = error.message;
      newState.valid = false;
      setStatus(newState.status);
      setInfo(newState.info);
    }
    return newState;
  };

  const updateNewPassword = (value: any) => {
    setValided((prev) => ({
      ...prev,
      password: true,
      number: value.search(/.*\d/) >= 0,
      special: /[^a-zA-Z0-9]/.test(value),
      length: value.length >= 8,
      min: value.search(/.*[a-z]/) >= 0,
      maj: value.search(/.*[A-Z]/) >= 0
    }));
    setNewPassword(value);
  };

  const updateNewPassword2 = (value: any) => {
    setNewPassword2(value);
  };

  return (
    <>
      <Practicio className="mb-6 h-auto w-52 max-w-full fill-accent" />
      <h2 className="title">
        <Translation keyName="editPassword.edit">Modifier votre mot de passe</Translation>
      </h2>

      {status === 'fail' && (
        <Alert className="alert mt-4" variant="danger">
          {info.message}
        </Alert>
      )}
      {status === 'success' && (
        <Alert className="alert mt-4" variant="success">
          {info.message}
        </Alert>
      )}
      <form
        className="sm:w-full lg:w-1/2"
        onSubmit={(event) => {
          event.preventDefault();
        }}>
        <div className="flex gap-4">
          <Form.Group className="mt-4 flex-1" controlId="verificationCode">
            <Form.Label className="pl-4 label">
              <Translation keyName="editPassword.code_email">
                Code de vérification reçu par email
              </Translation>
            </Form.Label>
            <Form.Control
              type="text"
              name="verificationCode"
              autoComplete="off"
              placeholder={t('editPassword.verif_code', 'Code de vérification')}
              onChange={(e) => setVerificationCode(e.target.value)}
              data-testid="resetpassword-verification-code-input"
              value={verificationCode}
              required
            />
          </Form.Group>

          <Form.Group className="mt-4 flex-1" controlId="email">
            <Form.Label className="pl-4 label">
              <Translation keyName="editPassword.your_email">Votre email</Translation>
            </Form.Label>
            <Form.Control
              type="text"
              name="email"
              data-testid="resetpassword-email-input"
              placeholder={t('general.email', 'Email')}
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              value={email}
              required
            />
          </Form.Group>
        </div>

        <div className="flex gap-4">
          <Form.Group className="mt-4 flex-1" controlId="newPassword">
            <Form.Label className="pl-4 label">
              <Translation keyName="editPassword.new_password">Nouveau mot de passe</Translation>
            </Form.Label>
            <div className="relative [&_.form-control]:pr-10">
              <Form.Control
                type={showPassword1 ? 'text' : 'password'}
                name="newPassword"
                data-testid="resetpassword-password-input"
                autoComplete="off"
                placeholder={t('editPassword.new_password', 'Nouveau mot de passe')}
                onChange={(e) => updateNewPassword(e.target.value)}
                value={newPassword}
                required
              />
              <button
                type="button"
                className="absolute right-4 top-1/2 -translate-y-1/2"
                onClick={() => setShowPassword1((prev) => !prev)}>
                <FontAwesomeIcon icon={!showPassword1 ? faEye : faEyeSlash} />
              </button>
            </div>
          </Form.Group>

          <Form.Group className="mt-4 flex-1" controlId="newPassword2">
            <Form.Label className="pl-4 label">
              <Translation keyName="editPassword.confirm">Confimation mot de passe</Translation>
            </Form.Label>
            <div className="relative [&_.form-control]:!pr-10">
              <Form.Control
                type={showPassword2 ? 'text' : 'password'}
                name="newPassword2"
                data-testid="resetpassword-password-confirm-input"
                autoComplete="off"
                placeholder={t('editPassword.confirm', 'Confimation mot de passe')}
                onChange={(e) => updateNewPassword2(e.target.value)}
                value={newPassword2}
                required
              />
              <button
                type="button"
                className="absolute right-4 top-1/2 -translate-y-1/2"
                onClick={() => setShowPassword2((prev) => !prev)}>
                <FontAwesomeIcon icon={!showPassword2 ? faEye : faEyeSlash} />
              </button>
            </div>
          </Form.Group>
        </div>
        <PasswordValidationIndicator valided={valided} className="mt-2" />

        <button
          className="cta cta--accent mt-4"
          onClick={submit}
          data-testid="resetpassword-save-button">
          <Translation keyName="general.save">Enregistrer</Translation>
        </button>
        <p className="mt-4 self-start">
          <LinkContainer to="/">
            <a className="link text-xs">
              <Translation keyName="general.login">Connexion</Translation>
            </a>
          </LinkContainer>
        </p>
      </form>
    </>
  );
};

export default EditPassword;
