import {
  SpeechConfig,
  AudioConfig,
  SpeechRecognizer,
  ResultReason,
  PropertyId,
  OutputFormat,
  PhraseListGrammar
} from 'microsoft-cognitiveservices-speech-sdk';
import log from 'loglevel';
import LoggingUtils from '@/utils/LoggingUtils';

// Configuration constants for speech recognition
const SPEECH_RECOGNITION_CONFIG = {
  INITIAL_SILENCE_TIMEOUT_MS: '10000', // 10 seconds timeout for initial silence
  END_SILENCE_TIMEOUT_MS: '1000' // 1 second timeout for end silence detection
};

export default class SpeechToTextManager {
  constructor(sdk) {
    this.sdk = sdk;
    this.speechConfig = null;
    this.audioConfig = null;
    this.recognizer = null;
    this.isListening = false;
    this.onSpeechStartDetectedCallbacks = [];
    this.onRecognizedCallbacks = [];
    this.onRecognizingCallbacks = [];
    this.onSpeechEndDetectedCallbacks = [];
    this.language = this.sdk.getLanguage() === 'en' ? 'en-US' : 'fr-FR';
    this.tokenExpirationTime = null;
    this.customSpeechEndpoint = process.env.REACT_APP_CUSTOM_SPEECH_ENDPOINT;
  }

  async initialize(isMicValidation = false, phraseList = []) {
    this.language = this.sdk.getLanguage() === 'en' ? 'en-US' : 'fr-FR';
    try {
      await this.refreshTokenIfNeeded(isMicValidation);
      const audioInputID = this.sdk.videoconf().mediaDevices().getAudioInputDeviceID();
      this.audioConfig = AudioConfig.fromMicrophoneInput(audioInputID);
      this.setupRecognizer(phraseList);
    } catch (error) {
      log.error('Failed to initialize speech recognition:', error);
    }
  }

  async refreshTokenIfNeeded(isMicValidation) {
    const tokenData = await this.sdk.getSpeechToken();
    if (tokenData) {
      const { token, region } = tokenData;
      this.speechConfig = SpeechConfig.fromAuthorizationToken(token, region);
      this.speechConfig.speechRecognitionLanguage = this.language;
      this.speechConfig.setProfanity(2); // Allow profanites to be sent over STT

      // Setup speech recognition properties
      // C# doc mentions
      // InitialSilenceTimeout (Used)
      // BabbleTimeout (Interesting and available on web side?)
      // EndSilenceTimeout (Used)
      // EndSilenceTimeoutAmbiguous (Interesting and available on web side?)

      // Set initial silence to detect if user didn't start speaking in time
      this.speechConfig.setProperty(
        PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
        SPEECH_RECOGNITION_CONFIG.INITIAL_SILENCE_TIMEOUT_MS
      );

      // Set end silence to detect end of speech
      this.speechConfig.setProperty(
        PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
        SPEECH_RECOGNITION_CONFIG.END_SILENCE_TIMEOUT_MS
      );

      if (!isMicValidation) {
        this.speechConfig.enableAudioLogging(); // Enable audio logging only when not in mic validation
      }

      if (this.customSpeechEndpoint) {
        this.speechConfig.endpointId = this.customSpeechEndpoint;
        this.speechConfig.setProperty(
          PropertyId.SpeechServiceConnection_EndpointId,
          this.customSpeechEndpoint
        );
      }
    } else {
      log.warn('Failed to get speech token, using previous configuration if available');
    }
  }

  setupRecognizer(phraseList = []) {
    if (this.recognizer) {
      this.recognizer.close();
    }
    if (this.speechConfig && this.audioConfig) {
      this.recognizer = new SpeechRecognizer(this.speechConfig, this.audioConfig);
      this.setupRecognizerCallbacks();

      // Add phrase list to the recognizer
      if (phraseList.length > 0) {
        const phraseListGrammar = PhraseListGrammar.fromRecognizer(this.recognizer);
        phraseList.forEach((phrase) => phraseListGrammar.addPhrase(phrase));
      }
    } else {
      throw new Error('Speech configuration or audio configuration is not set');
    }
  }

  setupRecognizerCallbacks() {
    this.recognizer.recognizing = (sender, event) => {
      const result = event.result;
      const sessionId = event.sessionId;
      log.debug(
        `[${LoggingUtils.FormatReadableTime(
          new Date().getTime()
        )}] Recognizing speech. Session ID: ${sessionId}. Result:`,
        result
      );
      this.onRecognizingCallbacks.forEach((callback) =>
        callback(result.text, result.privResultId, sessionId)
      );
    };

    this.recognizer.recognized = (sender, event) => {
      const result = event.result;
      const sessionId = event.sessionId;

      // Extract status
      const jsonData = JSON.parse(result.json);
      const recognitionStatus = jsonData.RecognitionStatus;
      const status = `${ResultReason[result.reason]}: ${recognitionStatus}`;

      log.debug(
        `[${LoggingUtils.FormatReadableTime(
          new Date().getTime()
        )}] Recognized speech. Session ID: ${sessionId}. Status: ${status}. Result:`,
        result
      );

      if (result.reason === ResultReason.RecognizedSpeech) {
        this.onRecognizedCallbacks.forEach((callback) =>
          callback(result.text, result.privResultId)
        );
      } else {
        this.onSpeechEndDetectedCallbacks.forEach((callback) => callback(sessionId, status));
      }
    };

    this.recognizer.canceled = (sender, event) => {
      log.debug(
        `[${LoggingUtils.FormatReadableTime(
          new Date().getTime()
        )}] Speech recognition canceled. Error: ${event.errorDetails}`
      );
      log.error(`Speech recognition canceled: ${event.errorDetails}`);

      // Notify all speech end detected callbacks with the error
      this.onSpeechEndDetectedCallbacks.forEach((callback) =>
        callback(event.sessionId, `Canceled: ${event.errorDetails}`)
      );
    };

    this.recognizer.sessionStarted = (sender, event) => {
      const sessionId = event.sessionId;
      log.debug(
        `[${LoggingUtils.FormatReadableTime(
          new Date().getTime()
        )}] Speech recognition session started. Session ID: ${sessionId}`
      );
      // You might want to add a callback for session start
      this.sdk.event().emit('speechRecognitionSessionStarted', { sessionId });
    };

    this.recognizer.speechStartDetected = (sender, event) => {
      log.debug(
        `[${LoggingUtils.FormatReadableTime(
          new Date().getTime()
        )}] Speech start detected. Session ID: ${event.sessionId}. Event: `,
        event
      );
      const sessionId = event.sessionId;
      this.onSpeechStartDetectedCallbacks.forEach((callback) => callback(sessionId));
    };

    this.recognizer.speechEndDetected = (sender, event) => {
      log.debug(
        `[${LoggingUtils.FormatReadableTime(
          new Date().getTime()
        )}] Speech end detected. Session ID: ${event.sessionId}. Event: `,
        event
      );
      const sessionId = event.sessionId;
      this.onSpeechEndDetectedCallbacks.forEach((callback) => callback(sessionId));
    };
  }

  async startListening() {
    if (this.isListening) return;

    try {
      await this.refreshTokenIfNeeded();
      this.isListening = true;
      this.recognizer.startContinuousRecognitionAsync(
        () => log.info('Continuous recognition started from STT Manager'),
        (error) => {
          log.error(`Error starting continuous recognition: ${error}`);
          // You might want to add a callback for errors
        }
      );
    } catch (error) {
      log.error('Failed to start listening:', error);
    }
  }

  stopListening() {
    if (!this.isListening) return;

    this.isListening = false;
    if (this.recognizer) {
      this.recognizer.stopContinuousRecognitionAsync(
        () => log.info('Continuous recognition stopped'),
        (error) => log.error(`Error stopping continuous recognition: ${error}`)
      );
    }
  }

  addOnSpeechStartDetectedCallback(callback) {
    this.onSpeechStartDetectedCallbacks.push(callback);
  }

  addOnRecognizedCallback(callback) {
    this.onRecognizedCallbacks.push(callback);
  }

  addOnRecognizingCallback(callback) {
    this.onRecognizingCallbacks.push(callback);
  }

  addOnSpeechEndDetectedCallback(callback) {
    this.onSpeechEndDetectedCallbacks.push(callback);
  }

  clearCallbacks() {
    this.onSpeechStartDetectedCallbacks = [];
    this.onRecognizedCallbacks = [];
    this.onRecognizingCallbacks = [];
    this.onSpeechEndDetectedCallbacks = [];
  }

  dispose() {
    this.stopListening();
    if (this.recognizer) {
      this.recognizer.close();
    }
  }

  async createTranscriptionSession(
    onSpeechStartDetectedCallback,
    onPartialTranscriptedTextCallback,
    onTranscriptedTextCallback,
    onSpeechEndDetectedCallback,
    isMicValidation = false,
    phraseList = []
  ) {
    await this.initialize(isMicValidation, phraseList);

    if (onSpeechStartDetectedCallback)
      this.addOnSpeechStartDetectedCallback(onSpeechStartDetectedCallback);

    if (onSpeechEndDetectedCallback)
      this.addOnSpeechEndDetectedCallback(onSpeechEndDetectedCallback);

    this.addOnRecognizedCallback((text, dataID) => {
      if (onTranscriptedTextCallback) onTranscriptedTextCallback(text, dataID);

      const data = { text, dataID };
      this.sdk.event().emit('transcriptedText', data); // to delete ?
    });

    this.addOnRecognizingCallback((text, dataID) => {
      if (onPartialTranscriptedTextCallback) onPartialTranscriptedTextCallback(text, dataID);

      const data = { text, dataID };
      this.sdk.event().emit('partialTranscriptedText', data); // to delete ?
    });

    return {
      start: async () => {
        await this.sdk.getSpeechToken(); // Ensure token is valid before starting
        this.startListening();
      },
      close: async () => {
        this.clearCallbacks();
        this.stopListening();
        await this.sdk.refreshSpeechTokenIfNeeded();
      }
    };
  }

  async createMicValidationSession(onSpeechDetectedCallback) {
    await this.initialize(true); // Pass true to indicate mic validation mode

    this.addOnRecognizedCallback(onSpeechDetectedCallback);
    this.addOnRecognizingCallback(onSpeechDetectedCallback);

    return {
      start: async () => {
        await this.sdk.getSpeechToken(); // Ensure token is valid before starting
        this.startListening();
      },
      close: async () => {
        this.stopListening();
        await this.sdk.refreshSpeechTokenIfNeeded();
      }
    };
  }

  // To delete or to refactor?
  // Won't never be reached.
  async handleFakeSession(onPartialTranscriptedTextCallback, onTranscriptedTextCallback) {
    const fakeSpeech = await this.getFakeUserSpeech();

    // Simulate partial speech
    if (onPartialTranscriptedTextCallback) {
      onPartialTranscriptedTextCallback(fakeSpeech);
    }
    this.sdk.event().emit('partialTranscriptedText', { text: fakeSpeech }); // to delete ?

    // Simulate final speech after a short delay
    setTimeout(() => {
      if (onTranscriptedTextCallback) {
        onTranscriptedTextCallback(fakeSpeech);
      }
      this.sdk.event().emit('transcriptedText', { text: fakeSpeech }); // to delete ?
    }, 1000);
  }

  async getFakeUserSpeech() {
    // Implement this method to generate or retrieve fake user speech
    return 'This is a fake user speech for testing purposes.';
  }
}
