export const USER_ACTION_TAGS = {
  BAD_ACTION: 'badAction',
  GOOD_ACTION: 'goodAction',
  LIMIT_CASE: 'limitCase',
  TECHNICAL_ACTION: 'technicalAction',
  NEUTRAL_ACTION: 'neutralAction',
  FEATURED_ACTION: 'featuredAction',
  MISSED_OPPORTUNITY: 'missedOpportunity'
};

export const STOP_TYPES = {
  FAILED: 'Failed',
  SUCCEEDEED: 'Succeeded'
};

export const FEEDBACK_EVALUATIONS = {
  GOOD: 'GOOD',
  BAD: 'BAD',
  FAIL: 'FAIL'
};
