import { useState, useEffect } from 'react';
import log from 'loglevel';

type PingStatus = {
  healthy: boolean;
  latency: number;
};

type PingControls = {
  start: () => void;
  stop: () => void;
};

const DEFAULT_PING_INTERVAL_IN_MS = 5000;
const DEFAULT_MAX_LATENCY_IN_MS = 3000;
const DEFAULT_MAX_LATENCY_STRIKES = 3;

export const usePingBackend = (
  pingInterval: number | null = DEFAULT_PING_INTERVAL_IN_MS,
  maxLatency: number | null = DEFAULT_MAX_LATENCY_IN_MS,
  maxLatencyStrikes: number | null = DEFAULT_MAX_LATENCY_STRIKES
): [PingStatus, PingControls] => {
  const effectivePingInterval = pingInterval ?? DEFAULT_PING_INTERVAL_IN_MS;
  const effectiveMaxLatency = maxLatency ?? DEFAULT_MAX_LATENCY_IN_MS;
  const effectiveMaxLatencyStrikes = maxLatencyStrikes ?? DEFAULT_MAX_LATENCY_STRIKES;

  const [status, setStatus] = useState<PingStatus>({ healthy: true, latency: 0 });
  const [isRunning, setIsRunning] = useState(true);
  const [_latencyStrikes, setLatencyStrikes] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const pingBackend = async () => {
      if (!isRunning) {
        return;
      }

      const start = Date.now();

      try {
        await window.sdk.fetchInternalAPI().run(`/ping?timestamp=${Date.now()}`, {
          method: 'GET'
        });

        const latency = Date.now() - start;

        if (latency > effectiveMaxLatency) {
          setLatencyStrikes((prevStrikes) => {
            const newStrikes = prevStrikes + 1;
            setStatus({
              healthy: newStrikes < effectiveMaxLatencyStrikes,
              latency
            });
            log.debug(
              `usePingBackend: Backend ping latency strike ${newStrikes}/${effectiveMaxLatencyStrikes}, latency: ${latency}ms`
            );
            return newStrikes;
          });
        } else {
          setLatencyStrikes(0);
          setStatus({ healthy: true, latency });
          log.debug(`usePingBackend: Backend ping is healthy, latency: ${latency}ms`);
        }
      } catch (error) {
        log.error('usePingBackend: Backend ping failed', error);
        setStatus({ healthy: false, latency: Infinity });
        setLatencyStrikes(effectiveMaxLatencyStrikes);
      }
    };

    let newIntervalId: NodeJS.Timeout | null = null;

    if (isRunning) {
      void pingBackend();
      newIntervalId = setInterval(pingBackend, effectivePingInterval);
      setIntervalId(newIntervalId);
    }

    return () => {
      if (newIntervalId) {
        clearInterval(newIntervalId);
      }
    };
  }, [effectivePingInterval, effectiveMaxLatency, effectiveMaxLatencyStrikes, isRunning]);

  const controls = {
    start: () => setIsRunning(true),
    stop: () => {
      setIsRunning(false);
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  };

  return [status, controls];
};
