import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import Card from '@UIKit/Card/Card';
import { Translation } from '../Utilities/Translation';
import React from 'react';

type ObjectiveCardProps = {
  objectives: string;
  label: string;
};

export const ObjectiveCard = ({ objectives, label }: ObjectiveCardProps) => (
  <Card className="mt-6 p-8" areCustomStyles={false} dataTestid="exercise-briefing-objectives">
    <Card.Border color="accent">
      <Card.Title
        color="accent"
        label={
          <Translation keyName="briefing.objectiveText">Votre rôle dans cet exercice</Translation>
        }>
        <h2
          className="mt-2 title"
          dangerouslySetInnerHTML={{
            __html: sanitizeHTML(label)
          }}
        />
      </Card.Title>
      <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(objectives) }} />
    </Card.Border>
  </Card>
);
