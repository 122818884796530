import React from 'react';
import ParticipantsModule from '@/AppClasses/Participants/ParticipantsModule';
import Bot from '../Bot';
import LocalUser from '../LocalUser';
import ToastsContainer from '@UIKit/Toasts/ToastsContainer';

const OneToOne = () => {
  const humanParticipant = ParticipantsModule.Instance.m_Humans[0] || {};
  const botParticipant = ParticipantsModule.Instance.m_Bots[0] || {};

  if (!humanParticipant.ID || !botParticipant.ID) {
    return null;
  }

  return (
    <>
      <div className="relative mx-auto w-4/5" key={botParticipant.ID}>
        <div className="pl-6 [&_.name]:bottom-auto [&_.name]:top-4">
          <Bot bot={botParticipant} />
        </div>
        <div className="absolute -bottom-6 z-10 w-1/3">
          <LocalUser human={humanParticipant} key={humanParticipant.ID} />
          <ToastsContainer
            className="absolute left-full top-1/3 -translate-x-4 pl-8"
            originSide="LEFT"
            translateY="BOTTOM_TO_TOP"
          />
        </div>
      </div>
    </>
  );
};

export default OneToOne;
