import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import Card from '@UIKit/Card/Card';
import { Translation } from '../Utilities/Translation';
import React from 'react';

type ContextCardProps = {
  context: string;
};

export const ContextCard = ({ context }: ContextCardProps) => {
  return (
    <Card className="p-8" areCustomStyles={false} dataTestid="exercise-briefing-context">
      <Card.Border>
        <Card.Title
          label={<Translation keyName="briefing.contextText">Le contexte en bref</Translation>}
        />
        <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(context) }} />
      </Card.Border>
    </Card>
  );
};
