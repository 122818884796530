import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import ValueString from './ValueString';
import NodePort from './Shared/NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';
import LoggingUtils from '@/utils/LoggingUtils';
import Utils from '../../Utils/Utils';
import FakeUser from '../../Participants/FakeUser';
import { SpeechSourceType } from '../ExerciseSessionHistory';
import { GraphEventTypes } from '../GraphNotifier/GraphEvent';

const MINIMUM_FAILED_STT_EVENTS_TO_NOTIFY = 1;

export default class SpeechToText extends ExerciseNode {
  // References
  transcriptionSession;

  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);
  FirstWord = new NodePort('FirstWord', 'output', this); // Connected to nodes waiting for first words event
  Result = new NodePort('Result', 'output', this); // Connected to string value nodes that store or use the string result of the speech recognition
  Failed = new NodePort('Failed', 'output', this); // If an error or timeout occurs

  // Parameters
  Endpoint = '';
  PhraseList = [];

  // Internal values
  m_Engine = 'MicrosoftCognitiveServicesSpeech';
  m_StartTime = null;
  m_PartialSpeechDetected = '';
  m_DetectedSpeech = '';
  m_LastSpeechDate = null;
  m_TranscriptionID = '';
  m_STTDataID = '';
  m_ReceivedAFinalizedSpeechSegment = false;
  m_ParentNode = null;
  m_SpeechSourceType = SpeechSourceType.human;
  m_TranscriptionServiceRunning = false;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.Endpoint = iProperties.Endpoint;
    this.PhraseList = iProperties.PhraseList;
    // For smart branching decision inclusion, we need to know the parent node
    this.m_ParentNode = iProperties.ParentNode;

    window.sdk.event().on('forceUserActions', (iUserActionIDs) => {
      this.OnUserFinishedTalking('forceUserActionsMode');
    });
  }

  async OnActivated(iActivationLink, iIsRewindMode = false) {
    await super.OnActivated(iActivationLink, iIsRewindMode);

    // Rewind mode
    if (iIsRewindMode) {
      return;
    }

    if (this.m_TranscriptionServiceRunning) {
      log.warn(
        this.GetIdentity() +
          ' OnActivated: Starting transcription when transcription service is already running!'
      );
    }

    // Initializations
    let human = ParticipantsModule.Instance.GetHuman();
    this.m_DetectedSpeech = '';
    this.m_PartialSpeechDetected = '';
    this.m_StartTime = new Date();
    this.m_LastSpeechDate = new Date();
    this.m_TranscriptionID = Utils.CreateObjectIDWithIncrement(
      this.m_StartTime,
      window.sdk.user().userID,
      this.m_Engine
    );
    this.m_STTDataID = '';
    this.m_SpeechSourceType = SpeechSourceType.human;

    // Activer le flag de transcription pour tous les modes (réel ou test)
    this.m_TranscriptionServiceRunning = true;

    // Test mode - force fake user speech: will force the speech to text result and skip the actual speech recognition
    if (window.testMode.fakeUserMode) {
      human.setSpeakingState('readyforSpeaking');
      this.m_SpeechSourceType = SpeechSourceType.synthetic;
      const fakeSpeech = await FakeUser.GetFakeUserSpeech(
        this.Graph.History.GetConversationAsText()
      );
      this.OnUserStartedTalking('fakeUserMode');
      this.OnPartialSpeechDetected(fakeSpeech);
      this.OnSpeechDetected(fakeSpeech, '', false);
      this.OnUserFinishedTalking('fakeUserMode');
      if (window.testMode.autoSkip) {
        setTimeout(() => {
          window.sdk.event().emit('skipExerciseStep');
        }, 1000);
      }
      return;
    }
    // Test mode - force fake user speech: will force the speech to text result and skip the actual speech recognition
    if (window.testMode.fakeUserSpeechToForce) {
      human.setSpeakingState('readyforSpeaking');
      this.m_SpeechSourceType = SpeechSourceType.forced;
      this.OnUserStartedTalking('forceSpeechMode');
      this.OnPartialSpeechDetected(window.testMode.fakeUserSpeechToForce);
      this.OnSpeechDetected(window.testMode.fakeUserSpeechToForce, '', false);
      this.OnUserFinishedTalking('forceSpeechMode');
      if (window.testMode.autoSkip) {
        setTimeout(() => {
          window.sdk.event().emit('skipExerciseStep');
        }, 1000);
      }
      window.testMode.fakeUserSpeechToForce = '';
      return;
    }
    // Test mode - force fake user actions: will force a dummy user speech and skip the actual speech recognition
    else if (window.testMode.forceUserActionsMode) {
      human.setSpeakingState('readyforSpeaking');
      this.m_SpeechSourceType = SpeechSourceType.placeholder;
      this.OnUserStartedTalking('forceUserActionsMode');
      this.OnPartialSpeechDetected('Fake user speech to force user actions.');
      this.OnSpeechDetected('Fake user speech to force user actions.', '', false);

      // If we are in S1 test mode with fakeUserAudioToPlay, end the speech recognition after 1 second
      if (window.testMode.fakeUserAudioToPlay) {
        setTimeout(() => {
          this.OnUserFinishedTalking('forceUserActionsMode');
        }, 1000);
      }

      return;
    }

    // Setup the phrase list
    let phraseListToUse = [];
    if (this.PhraseList && this.PhraseList.length) {
      // If PhraseList is provided, use it
      phraseListToUse = this.PhraseList;
    } else if (
      this.Graph.ExerciseSettings.STTPhraseList &&
      this.Graph.ExerciseSettings.STTPhraseList.length
    ) {
      // If PhraseList is not provided, use STTPhraseList from ExerciseSettings
      phraseListToUse = this.Graph.ExerciseSettings.STTPhraseList;
    }
    // If both are empty, phraseListToUse will remain an empty array

    this.transcriptionSession = await window.sdk
      .videoconf()
      .getSpeechToTextManager()
      .createTranscriptionSession(
        // Speech start detection callback
        this.OnUserStartedTalking.bind(this),
        // Partial speech detection callback
        this.OnPartialSpeechDetected.bind(this),
        // Speech segment detection callback
        this.OnSpeechDetected.bind(this),
        // Speech end detection callback
        this.OnUserFinishedTalking.bind(this),
        false, // isMicValidation
        phraseListToUse // Pass the phrase list to the transcription session
      );
    this.transcriptionSession.start();

    // Indicate to the user that the speech recognition is ready
    human.setSpeakingState('readyforSpeaking');
  }

  async OnUserStartedTalking(iSessionID) {
    log.debug(
      this.GetIdentity() + ' OnUserStartedTalking: user started talking. Session ID: ' + iSessionID
    );

    // If inactive, ignore
    if (!this.IsActive()) {
      log.debug(this.GetIdentity() + ' OnUserStartedTalking: ignored because node is inactive!');
      return;
    }

    // If paused, ignore
    if (this.IsPaused()) {
      log.debug(this.GetIdentity() + ' OnUserStartedTalking: ignored because node is paused!');
      return;
    }

    // Show "Vous parlez" state on the user's video slot
    let human = ParticipantsModule.Instance.GetHuman();
    human.setSpeakingState('speaking');

    log.debug(this.GetIdentity() + ' OnUserStartedTalking.');

    this.FirstWord.Activate();

    // If this node is a child of a smart branching decision, we need to send the first word to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnUserStartedTalking();
    }
  }

  // Triggered when the STT detects partial speech
  OnPartialSpeechDetected(iResult, iSTTDataID = '') {
    // Lock the event listener to avoid multiple calls
    if (!this.m_TranscriptionServiceRunning) {
      return;
    }

    // Temporary cleaning of the STT result
    iResult = this.STTResultCleaning(iResult);

    // If inactive, ignore
    if (!this.IsActive()) {
      log.debug(
        this.GetIdentity() +
          " OnPartialSpeechDetected: '" +
          iResult +
          "' ignored because node is inactive!"
      );
      return;
    }

    log.debug(this.GetIdentity() + " OnPartialSpeechDetected: '" + iResult + "'.");

    this.m_LastSpeechDate = new Date();
    this.m_PartialSpeechDetected = iResult;

    // Send the string result to the Exercise React component
    window.sdk
      .event()
      .emit('setSpeechFeedbackText', this.m_DetectedSpeech + ' ' + this.m_PartialSpeechDetected);

    // If this node is a child of a smart branching decision, send the speech to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnPartialSpeechDetected(iResult);
    }
  }

  // Triggered when the STT receives a recognized speech segment
  OnSpeechDetected(iResult, iSTTDataID = '', isAutoSegmentation = false) {
    // Lock the event listener to avoid multiple calls
    if (!this.m_TranscriptionServiceRunning) {
      return;
    }

    // Temporary cleaning of the STT result
    iResult = this.STTResultCleaning(iResult);

    // If inactive, ignore
    if (!this.IsActive()) {
      log.debug(
        this.GetIdentity() +
          " OnSpeechDetected: '" +
          iResult +
          "' ignored because node is inactive!"
      );
      return;
    }

    // If paused, ignore
    if (this.IsPaused()) {
      log.debug(
        this.GetIdentity() + " OnSpeechDetected: '" + iResult + "' ignored because node is paused!"
      );
      return;
    }

    log.debug(this.GetIdentity() + " OnSpeechDetected: '" + iResult + "'.");

    // Track the STT data ID to be able to link the speech to the STT data (audio file)
    this.m_STTDataID = iSTTDataID;

    if (this.m_DetectedSpeech) {
      // Add the result to the accumulated speech
      this.m_DetectedSpeech += ' ' + iResult;
      log.debug(this.GetIdentity() + " Accumulated speech: '" + this.m_DetectedSpeech + "'.");
    } else {
      this.m_DetectedSpeech = iResult;
    }

    // If this node is a child of a smart branching decision, send the speech to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnSpeechSegmentation(this.m_DetectedSpeech);
    }
  }

  async OnUserFinishedTalking(iSessionID, error = null) {
    // Lock the event listener to avoid multiple calls
    if (!this.m_TranscriptionServiceRunning) {
      return;
    }
    this.m_TranscriptionServiceRunning = false;

    // If this end event is triggered by an error, trigger the fail event
    if (error) {
      this.OnFailed(error);
      return;
    }

    log.debug(
      this.GetIdentity() + ' OnUserFinishedTalking: user stopped talking. Session ID: ' + iSessionID
    );

    if (this.transcriptionSession) {
      log.debug(
        this.GetIdentity() +
          ' OnUserFinishedTalking: closing transcription session with ID ' +
          iSessionID
      );
      this.transcriptionSession.close();
    }

    // Log action to history
    // Must be bloking/awaited to ensure the history event is created before triggering the parent/output
    const historyEventID = await this.Graph.History.AddUserSpeech(
      this.ID,
      '', // Will be set later, when the analysis task is created
      this.m_DetectedSpeech,
      new Date(),
      this.Graph.LastBranchingDecisionNode.DatabaseID,
      this.m_SpeechSourceType
    );

    // Beautify and log the speech to DynamoDB
    this.BeautifyAndLogSpeechToDatabase(historyEventID);

    // Send the string result to the debug info UI
    window.sdk.event().emit('setSpeechFeedbackText', this.m_DetectedSpeech);
    this.OutputResult();

    let human = ParticipantsModule.Instance.GetHuman();
    human.setSpeakingState('no');

    // Reset
    this.m_PartialSpeechDetected = '';
    this.m_StartTime = null;

    this.ActivateOutput();

    // If this node is a child of a smart branching decision, send the speech to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnSpeechDetected(this.m_DetectedSpeech);
    }
  }

  // Temp method that replaces "rue" and "revue" word by "réu" in the intput string (STT result)
  // Will be deleted when the STT will be able to recognize "réu" (for "réunion")
  STTResultCleaning(iResult) {
    const result = iResult.replace(/\b(rue|rues|ru)\b/gi, 'réu');
    return result;
  }

  async BeautifyAndLogSpeechToDatabase(historyEventID) {
    // Copy input variables to local variables to guarantee their values stay the same during the async calls
    const detectedSpeech = this.m_DetectedSpeech;
    const transcriptionID = this.m_TranscriptionID;
    const startTime = new Date(this.m_StartTime);
    const sttDataID = this.m_STTDataID;
    const speechSourceType = this.m_SpeechSourceType;

    const endTime = new Date();
    const elapsedTranscriptionTimeMs = endTime - startTime;

    // Get the branching decision database ID
    let branchingDecisionDatadaseID = 'undefined';
    if (this.m_ParentNode) {
      branchingDecisionDatadaseID = this.m_ParentNode.DatabaseID;
    } else if (this.Graph.LastBranchingDecisionNode.DatabaseID) {
      branchingDecisionDatadaseID = this.Graph.LastBranchingDecisionNode.DatabaseID;
    }

    // Log to test variables
    if (window.testMode.fillAppStateValues) {
      window.testMode.appStateValues['lastSpeechToText'] = detectedSpeech;
    }

    // Log to DynamoDB
    const analysisTask = await window.sdk.AnalysisTask().createOne(
      branchingDecisionDatadaseID, // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      this.m_Engine, // analyzer Engine
      '1', // Analyzer Version
      'raw', // Analysis Status
      transcriptionID, // Analysis Input
      startTime, // Start Time
      elapsedTranscriptionTimeMs.toString(), // Analysis duration (milliseconds)
      '', // Possible choices
      {
        transcript: detectedSpeech,
        STTDataID: sttDataID,
        language: window.sdk.getLanguage() === 'en' ? 'en-US' : 'fr-FR'
      }, // Analysis Result
      this.Graph.ExerciseID.toString() // Exercise ID
    );

    // Add the analysis task ID to the history event
    await this.Graph.History.UpdateUserSpeech(
      historyEventID,
      this.ID,
      analysisTask.ID,
      detectedSpeech,
      branchingDecisionDatadaseID,
      speechSourceType,
      ''
    );

    // Create a beautified version of the user speech
    let beautifiedSpeech = '';

    if (window.testMode.forceUserActionsMode) {
      beautifiedSpeech = 'Fake user speech to force user actions.';
    } else {
      beautifiedSpeech = (await this.BeautifyUserSpeech(detectedSpeech)) || '';
    }

    // Update the history event with the beautified speech
    await this.Graph.History.UpdateUserSpeech(
      historyEventID,
      this.ID,
      analysisTask.ID,
      detectedSpeech,
      branchingDecisionDatadaseID,
      speechSourceType,
      beautifiedSpeech
    );
  }

  // Triggered when the STT finished the speech recognition (EndSilence timeout)
  OnFailed(iReason) {
    log.debug(
      `${LoggingUtils.FormatReadableTime(
        new Date()
      )} - ${this.GetIdentity()} OnFailed: Reason '${iReason}'.`
    );

    if (this.Graph.IsStopped()) {
      log.debug(this.GetIdentity() + ' OnFailed: Graph is stopped, ignoring.');
      return;
    }

    if (this.transcriptionSession) {
      this.transcriptionSession.close();
    }

    // Log to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.Graph.LastBranchingDecisionNode.DatabaseID
        ? this.Graph.LastBranchingDecisionNode.DatabaseID
        : 'undefined', // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      this.m_Engine, // analyzer Engine
      '1', // Analyzer Version
      'failed', // Analysis Status
      this.m_TranscriptionID, // Analysis Input
      this.m_StartTime, // Start Time
      (new Date().getTime() - this.m_StartTime.getTime()).toString(), // Analysis duration (milliseconds)
      '', // Possible choices
      "Failed! Reason: '" +
        iReason +
        "'. Partial speech detected: '" +
        this.m_PartialSpeechDetected +
        "'.",
      this.Graph.ExerciseID.toString() // Exercise ID // Analysis Result
    );

    this.Graph.History.AddDetectionIssueEvent(this.ID, 'sttFailed');

    if (this.ShouldNotifyDetectionIssue()) {
      this.Graph.Notifier.notify({
        type: GraphEventTypes.DetectionIssue,
        content: { reason: 'sttFailed' }
      });
    }

    let human = ParticipantsModule.Instance.GetHuman();
    human?.setSpeakingState('no');

    // Reset
    this.m_PartialSpeechDetected = '';
    this.SetActive(false);

    // Output to 'Failed' port
    this.Failed.Activate();

    // If this node is a child of a smart branching decision, send the failed event to the parent node
    if (this.m_ParentNode) {
      this.m_ParentNode.OnSTTFailed();
    }
  }

  ShouldNotifyDetectionIssue() {
    const detectionIssueEvents = this.Graph.History.GetDetectionIssueEvents('sttFailed');
    return detectionIssueEvents.length > MINIMUM_FAILED_STT_EVENTS_TO_NOTIFY;
  }

  // Send the string result to the nodes connected to the SpeechResult_OutputNodes port
  OutputResult() {
    log.debug(
      this.GetIdentity() +
        " OutputResult '" +
        this.m_DetectedSpeech +
        "' to " +
        this.Result.GetConnectionsCount() +
        ' nodes: ' +
        this.Result.ListPortConnections()
    );

    this.Result.GetConnectedNodes().forEach((node) => {
      if (node instanceof ValueString) {
        node.SetValue(this.m_DetectedSpeech);
      }
    });
  }

  Resume() {
    super.Resume();

    if (this.IsActive()) {
      this.OnFailed('Node was paused while waiting for speech recognition.');
    }
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.Activate();
  }

  GetStringValue() {
    return this.m_DetectedSpeech;
  }

  OnDeactivated() {
    log.debug(
      this.GetIdentity() + " OnDeactivated: speech found = '" + this.m_DetectedSpeech + "'."
    );

    // Stop current speech recognition if running
    if (this.transcriptionSession) {
      this.transcriptionSession.close();
    }

    super.OnDeactivated();
  }

  async BeautifyUserSpeech(iUserSpeech) {
    // @TODO: for now, for S2/S3/S4/Sn ... we only have one bot, so we can hardcode
    const botName = ParticipantsModule.Instance.GetAllBots()[0].Name;
    const botGender = ParticipantsModule.Instance.GetAllBots()[0].Gender || 'M';
    const contextualInfo = this.Graph.ExerciseSettings.PromptContextualInfo || '';

    try {
      const gptAnswer = await window.sdk.fetchInternalAPI().post('/llm/beautify-user-speech', {
        body: {
          userSpeech: iUserSpeech,
          botName,
          botGender,
          contextualInfo,
          exerciseSessionID: this.Graph.CurrentExerciseSessionID
        }
      });

      return gptAnswer.beautifiedUserSpeech;
    } catch (error) {
      log.error(`SpeechToText.BeautifyUserSpeech: ${error}`);
    }

    return null;
  }

  PrintParameters() {
    //log.debug("SpeechToText: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", End Silence = " + this.EndSilence);
  }

  //////////////////////////
  // Test functions
  //////////////////////////

  TestExecute(iActivationLink, iTestReport) {
    // Initialize the test
    //this.m_ExecutionMode = "Test"; Not needed?
    this.m_DetectedSpeech = iTestReport.UserSpeech;

    this.Output.TestActivateAllConnections(iTestReport);
  }
}
