import { uniqBy } from '@/AppClasses/Utils/uniqBy';
import { HistoryEventTypes } from '@/AppClasses/ExerciseScenario/ExerciseSessionHistory';
import AbstractSolver from '../AbstractSolver';
import { FEEDBACK_EVALUATIONS, USER_ACTION_TAGS } from '../constants';
import { SceneEvaluationEvent, UserActionFeedback } from '../SolverTypes';

export default class ActEvaluationSolver extends AbstractSolver {
  /**
   * Evaluates the current act based on scene evaluation events
   */
  SolveActEvaluation(): {
    evaluation: string;
    userActionsFeedbacks: Array<UserActionFeedback>;
  } {
    const sceneEvaluationEvents = this.GetSceneEvaluationEvents();
    const evaluation = this.DetermineEvaluation(sceneEvaluationEvents);
    const userActionsFeedbacks = this.ProcessUserActionsFeedbacks(
      sceneEvaluationEvents,
      evaluation
    );

    return {
      evaluation,
      userActionsFeedbacks
    };
  }

  /**
   * Retrieves scene evaluation events for the current act
   * @private
   */
  private GetSceneEvaluationEvents(): SceneEvaluationEvent[] {
    return this.Graph.History.GetAllEventsBy({
      EventType: HistoryEventTypes.SCENE_EVALUATION,
      'Content.ActName': this.Graph.GetCurrentActName()
    });
  }

  /**
   * Processes and sorts user actions feedbacks from scene evaluation events
   * @private
   */
  private ProcessUserActionsFeedbacks(
    iSceneEvaluationEvents: SceneEvaluationEvent[],
    iEvaluation: string
  ): UserActionFeedback[] {
    const allUserActionsFeedbacks = iSceneEvaluationEvents.flatMap(
      (event) => event.Content.UserActionsFeedbacks || []
    );

    const uniqueUserActionsFeedbacks = uniqBy(
      allUserActionsFeedbacks,
      (uaf: UserActionFeedback) => uaf.ID
    );
    const sortedUserActionsFeedbacks = uniqueUserActionsFeedbacks.sort(
      (a, b) => b.PriorityRank - a.PriorityRank
    );

    if (iEvaluation === FEEDBACK_EVALUATIONS.GOOD) {
      return sortedUserActionsFeedbacks;
    }

    if (iEvaluation === FEEDBACK_EVALUATIONS.BAD) {
      return sortedUserActionsFeedbacks.filter(
        (uaf) =>
          uaf.Tags.includes(USER_ACTION_TAGS.BAD_ACTION) ||
          uaf.Tags.includes(USER_ACTION_TAGS.MISSED_OPPORTUNITY)
      );
    }

    // FAIL evaluation
    return sortedUserActionsFeedbacks.filter((uaf) =>
      uaf.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)
    );
  }

  /**
   * Determines the overall evaluation based on scene evaluation events
   * @private
   */
  private DetermineEvaluation(iSceneEvaluationEvents: SceneEvaluationEvent[]): string {
    const hasBadEvaluation = iSceneEvaluationEvents.some(
      (event) => event.Content.Evaluation === FEEDBACK_EVALUATIONS.BAD
    );

    const hasFailEvaluation = iSceneEvaluationEvents.some(
      (event) => event.Content.Evaluation === FEEDBACK_EVALUATIONS.FAIL
    );

    if (hasFailEvaluation) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    if (hasBadEvaluation) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    return FEEDBACK_EVALUATIONS.GOOD;
  }
}
