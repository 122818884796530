import React from 'react';
import Modal from '@UIKit/Modal/Modal';
import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import SmCover from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates-sm.jpg';
import SmCover2x from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates-sm@2x.jpg';
import Cover from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates.jpg';
import Cover2x from '@/components/Feedback/FeedbackDetail/images/cover-with-teammates@2x.jpg';

type RecommendationDetailModalProps = {
  isOpen: boolean;
  title: string;
  content: string;
  onClose: () => void;
};

export const RecommendationDetailModal = ({
  isOpen,
  onClose,
  title,
  content
}: RecommendationDetailModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <Modal.Header
        cover={{
          alt: '',
          smImage: {
            src: SmCover,
            height: 250,
            width: 640
          },
          smImage2x: {
            src: SmCover2x,
            height: 500,
            width: 1280
          },
          image: {
            src: Cover,
            height: 250,
            width: 1000
          },
          image2x: {
            src: Cover2x,
            height: 500,
            width: 2000
          }
        }}>
        <Modal.Title label={<>Tout savoir sur&hellip;</>}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <div dangerouslySetInnerHTML={{ __html: sanitizeHTML(content) }}></div>
      </Modal.Content>
    </Modal>
  );
};
