import React from 'react';
import log from 'loglevel';
import '@/styles/components/Exercise.scss';
import '@/styles/components/step/new-step.scss';
import { isAndroid, isSafari } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import GetUserMedia from '@/components/MediaDevices/GetUserMedia';
import Settings from '@/components/MediaDevices/Settings';
import TopBar from '@/components/TopBar/TopBar';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '@/contexts/ExerciseContext';
import DebugPanel from '../VisioExercise/DebugPanel';
import Authorization from './Step/Authorization';
import Configuration from './Step/Configuration';
import Equipment from './Step/Equipment';
import Error from './Step/Error';
import MenuAccessDevice from './Step/MenuAccessDevice';
import NeedInteraction from './Step/NeedInteraction';
import UpdateSettings from './Step/UpdateSettings';
import Welcome from './Step/Welcome';

export function withRouter(Children) {
  return (props) => {
    const navigate = useNavigate();
    return <Children {...props} navigate={navigate} />;
  };
}

class ExerciseSetup extends React.Component {
  static contextType = ExerciseContext;

  // Dynamic
  state = {
    step: 'notInitialized',
    micIsOk: false,
    getUserMediaState: {},
    onShowLoaderWaitWebcam: true
  };

  isStreamReady = false;
  // --------
  // Initialisation
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.context.showHelpBtn();

    if (
      window.sdk.user().firtTimeUserExp() &&
      !window.sdk.user().skipFirstTimeUserExpOnboarding()
    ) {
      this.context.setStep('welcome');
      return;
    }

    if (!this.context.step) {
      this.startWaitingForWebcam();
    }
  }

  componentDidUpdate() {
    if (this.context.step === 'startWaitingForWebcam') {
      this.startWaitingForWebcam();
    }
  }

  async handleDeviceChange(event) {
    let devices = await event.target.enumerateDevices();
    let currentDeviceIdVideo = window.sdk
      .videoconf()
      .mediaDevices()
      .getCurrentDeviceId('videoinput');
    let currentDeviceIdAudio = window.sdk
      .videoconf()
      .mediaDevices()
      .getCurrentDeviceId('audioinput');

    let findedAudio = false;
    let findedVideo = false;

    for (const i in devices) {
      if (devices[i].kind === 'audioinput' && devices[i].deviceId === currentDeviceIdAudio)
        findedAudio = true;
      if (devices[i].kind === 'videoinput' && devices[i].deviceId === currentDeviceIdVideo)
        findedVideo = true;
    }

    if (!findedAudio || !findedVideo) {
      window.sdk.event().emit('onDeviceChanged', findedAudio ? 'webcam' : 'microphone');
    }

    log.debug(
      'DEVICE_CHANGE',
      'Changement détecté dans les périphériques de média',
      await event.target.enumerateDevices()
    );
  }

  // --------
  // Lors de la suppresion du composant
  async componentWillUnmount() {
    log.debug('ExerciseSetup.componentWillUnmount');
    window.sdk.videoconf().mediaDevices().stop();
  }

  // Handle microphone working checkbox
  handleMicIsOkChange = (event) => {
    this.setState({ micIsOk: event.target.checked });
  };

  // --------
  // Check si les autorisations audio/camera sont correctes
  // Si c'est le cas, on passe au Briefing
  // Sinon on affiche le tuto pour activer les autorisations
  startWaitingForWebcam() {
    log.debug('ExerciseSetup.startWaitingForWebcam');
    if (!window.sdk.videoconf().mediaDevices().isStreamReady()) {
      log.debug('ExerciseSetup.startWaitingForWebcam: Stream not ready, waiting...');
      this.context.setStep('authorization');
      //this.setState({ step: "waitWebcam" });
    } else {
      log.debug('ExerciseSetup.startWaitingForWebcam: Stream ready');
      this.OnStreamReady();
    }
  }

  OnStreamReady = async () => {
    if (this.isStreamReady) {
      return;
    }

    this.isStreamReady = true;
    log.debug('ExerciseSetup.OnStreamReady');
    this.context.setStep('configuration');
  };

  startCamMicSetup() {
    if (window.zE) {
      window.zE('webWidget', 'show');
      window.zE('webWidget:on', 'close', function () {
        window.zE('webWidget', 'show');
      });
    }
    log.debug('ExerciseSetup.CamMicSetup');

    if (
      this.context.jsonGraph.CustomizationValues &&
      this.context.jsonGraph.CustomizationValues.skipCamMicSetup
    ) {
      this.setState({ step: 'briefing' });
    } else {
      this.setState({ step: 'camMicSetup' });
    }
  }

  backToCamMicSetup = async () => {
    this.context.setStep('camMicSetup');
  };

  ////////////////////////////////////////////////////////////////////////////////
  // Rendering
  ////////////////////////////////////////////////////////////////////////////////

  onButtonToShow = (changeBrowser, camUsed, showPopinInfo, showPopinRefuse, waitAuthorization) => {
    this.setState({
      getUserMediaState: {
        changeBrowser,
        camUsed,
        showPopinInfo,
        showPopinRefuse,
        waitAuthorization
      }
    });
  };

  onShowLoaderWaitWebcam = (value) => {
    this.setState({
      onShowLoaderWaitWebcam: value
    });
  };

  renderGetUserMedia() {
    return (
      <>
        {this.state.onShowLoaderWaitWebcam && (
          <div className="spinner-loader dark mx-auto block"></div>
        )}

        <GetUserMedia
          showLoader={this.onShowLoaderWaitWebcam}
          onButtonToShow={this.onButtonToShow}
          onStreamReady={this.OnStreamReady}
          askOnLoad={true}
        />
      </>
    );
  }

  renderCamMicSetup() {
    return (
      <div className={`exercise-presentation exercise-presentation--2 exercise__inner`}>
        <div className="exercise__content">
          <Settings />
        </div>
      </div>
    );
  }

  renderThanks() {
    return (
      <p className="mx-auto max-w-row text-center title">
        <Translation keyName="exercise.thanks">Merci, au revoir</Translation>
      </p>
    );
  }

  renderError() {
    return <div className="exercise__inner exercise_error_message">{this.state.failMessage}</div>;
  }

  renderGetUserMediaButton() {
    if (this.state.getUserMediaState.camUsed)
      return (
        <button
          className="cta cta--white"
          onClick={() => {
            window.location.reload();
          }}>
          <Translation keyName="exercise.reload">Rafraichir</Translation>
        </button>
      );

    if (this.state.getUserMediaState.showPopinInfo)
      return (
        <button
          className="cta cta--accent"
          onClick={() => {
            window.sdk.event().emit('getUserMediaAsk');
          }}>
          <Translation keyName="exercise.ask_auth">Demandez l'autorisation</Translation>
        </button>
      );

    if (this.state.getUserMediaState.showPopinRefuse)
      return (
        <>
          {(isSafari || isAndroid) && (
            <button
              className="cta cta--accent"
              onClick={() => {
                window.location.reload();
              }}>
              <Translation keyName="general.retry">Rééssayer</Translation>
            </button>
          )}
          {!isSafari && !isAndroid && this.state.getUserMediaState.waitAuthorization && (
            <button
              className="cta cta--accent"
              onClick={() => {
                window.location.reload();
              }}>
              <Translation keyName="exercise.check_authorisation">
                Vérifiez l'autorisation
              </Translation>
            </button>
          )}
          {!this.state.getUserMediaState.waitAuthorization && (
            <button
              className="cta cta--accent"
              onClick={() => {
                window.sdk.event().emit('getUserMediaAsk2');
              }}>
              <Translation keyName="exercise.ask_auth">Demandez l'autorisation</Translation>
            </button>
          )}
        </>
      );
  }

  goToBriefing = () => {
    const { navigate } = this.props;
    // window.location.href = `/exercise/${this.props.ExerciseID}/briefing`;
    navigate(`/exercise/${this.props.ExerciseID}/briefing`);
  };

  render() {
    let content = null;
    switch (this.context.step) {
      case 'welcome':
        content = <Welcome />;
        break;
      case 'materiel':
        content = <Equipment />;
        break;
      case 'authorization':
        if (window.testMode.noDevicesMode) {
          this.goToBriefing();
        } else {
          content = <Authorization />;
        }
        break;
      case 'configuration':
        if (window.testMode.noDevicesMode) {
          this.goToBriefing();
        } else {
          content = <Configuration />;
        }
        break;
      case 'settings':
        content = <UpdateSettings />;
        break;
      case 'initialization':
        content = <NeedInteraction />;
        break;
      case 'waitWebcam':
        content = this.renderGetUserMedia();
        break;
      case 'camMicSetup':
        content = this.renderCamMicSetup();
        break;
      case 'micValidation':
        this.goToBriefing();
        break;
      case 'thanks':
        content = this.renderThanks();
        break;
      case 'fail':
        content = <Error message={this.state.failMessage} />;
        break;
    }

    return (
      <>
        <TopBar className="sticky left-0 top-0 z-50 w-full" areButtonsHidden={true} />
        <div className="centered-wide-row flex min-h-[calc(100vh-theme(spacing.12))] flex-col py-8">
          <DebugPanel />

          <div className="exercise__main flex flex-1 flex-col">
            {window.sdk.user().firtTimeUserExp() === 'unknown_user' &&
              ['materiel', 'authorization', 'configuration'].includes(this.context.step) && (
                <MenuAccessDevice className="!mb-6" active={this.context.step} />
              )}

            <div className="my-auto">{content}</div>
          </div>

          {['camMicSetup'].includes(this.context.step) && (
            <BottomBar className="exercise__bottom-bar">
              {this.context.step === 'camMicSetup' && (
                <button
                  className="cta cta--accent"
                  id="camMicSetup-join-button"
                  onClick={() => {
                    this.context.setStep('micValidation');
                  }}>
                  Suivant
                </button>
              )}
            </BottomBar>
          )}
          <p className="mt-4 text-xs">
            Practicio {window.infoVersion.version}{' '}
            {window.sdk._env !== 'prod' && window.sdk.exerciseSessionID
              ? `- SessionID: ${window.sdk.exerciseSessionID}`
              : ''}
          </p>
        </div>
      </>
    );
  }

  //////
  // Error handling
  componentDidCatch(error, errorInfo) {
    log.error('ExerciseSetup error: ', error, errorInfo);
  }
}

export default withRouter(ExerciseSetup);
