import { uniqBy } from '@/AppClasses/Utils/uniqBy';
import AbstractSolver from '@/AppClasses/ExerciseScenario/Solvers/AbstractSolver';
import {
  USER_ACTION_TAGS,
  FEEDBACK_EVALUATIONS
} from '@/AppClasses/ExerciseScenario/Solvers/constants';
import { HistoryEventTypes } from '@/AppClasses/ExerciseScenario/ExerciseSessionHistory';
import { SceneEvaluationResult, BranchingDecisionResult, UserActionFeedback } from '../SolverTypes';

export default class SceneEvaluationSolver extends AbstractSolver {
  /**
   * Evaluates the current scene based on branching decision results
   * @returns {Object} Object containing evaluation result and filtered user actions feedbacks
   */
  SolveSceneEvaluation(): SceneEvaluationResult {
    const initialEvaluation = {
      evaluation: FEEDBACK_EVALUATIONS.GOOD,
      userActionsFeedbacks: []
    };

    const branchingDecisionResults = this.Graph.History.GetAllEventsBy({
      EventType: HistoryEventTypes.BRANCHING_DECISION_RESULTS,
      'Content.NodeID': this.Graph.LastBranchingDecisionNode.ID
    });

    if (branchingDecisionResults.length === 0) {
      return initialEvaluation;
    }

    const userActionFeedbacks = this.ProcessUserActionsFeedbacks(branchingDecisionResults);

    if (userActionFeedbacks.length === 0) {
      return initialEvaluation;
    }

    const evaluation = this.DetermineEvaluation(userActionFeedbacks[0]);
    const filteredUserActionsFeedbacks = this.FilterUserActionsFeedbacksByEvaluation(
      userActionFeedbacks,
      evaluation
    );

    return { evaluation, userActionsFeedbacks: filteredUserActionsFeedbacks };
  }

  /**
   * Processes and sorts user actions feedbacks from branching decision results
   * @private
   * @param {Array} iBranchingDecisionResults - Array of branching decision results
   * @returns {Array} Sorted unique user actions feedbacks
   */
  ProcessUserActionsFeedbacks(
    iBranchingDecisionResults: BranchingDecisionResult[]
  ): UserActionFeedback[] {
    const allUserActionsFeedbacks = [];

    for (const BDResult of iBranchingDecisionResults) {
      const uafs = BDResult.Content.BranchingDecisionResults.FinalUserActionsFeedbacks || [];
      for (const uaf of uafs) {
        allUserActionsFeedbacks.push({
          ...uaf,
          BranchingDecisionNodeID: this.Graph.LastBranchingDecisionNode.ID,
          SceneNodeID: this.Graph.GetCurrentSceneNodeID(),
          BranchingDecisionDatabaseID: BDResult.Content.BranchingDecisionDatabaseID
        });
      }
    }

    return uniqBy(allUserActionsFeedbacks, (uaf: UserActionFeedback) => uaf.ID).sort(
      (a, b) => b.PriorityRank - a.PriorityRank
    );
  }

  /**
   * Determines the evaluation based on the highest priority user action feedback
   * @private
   * @param {Object} iHighestPriorityUserActionFeedback - User action feedback with highest priority
   * @returns {string} Evaluation result (GOOD, BAD, or FAIL)
   */
  DetermineEvaluation(iHighestPriorityUserActionFeedback: UserActionFeedback): string {
    if (iHighestPriorityUserActionFeedback.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    if (
      iHighestPriorityUserActionFeedback.Tags.includes(USER_ACTION_TAGS.BAD_ACTION) ||
      iHighestPriorityUserActionFeedback.Tags.includes(USER_ACTION_TAGS.MISSED_OPPORTUNITY)
    ) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    return FEEDBACK_EVALUATIONS.GOOD;
  }

  /**
   * Filters user actions feedbacks based on the evaluation result
   * @private
   * @param {Array} iUserActionsFeedbacks - Array of user action feedbacks
   * @param {string} iEvaluation - Evaluation result to filter by
   * @returns {Array} Filtered user actions feedbacks
   */
  FilterUserActionsFeedbacksByEvaluation(
    iUserActionsFeedbacks: UserActionFeedback[],
    iEvaluation: string
  ): UserActionFeedback[] {
    const filterCriteria = {
      [FEEDBACK_EVALUATIONS.GOOD]: (uaf: UserActionFeedback) =>
        uaf.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION),
      [FEEDBACK_EVALUATIONS.BAD]: (uaf: UserActionFeedback) =>
        uaf.Tags.includes(USER_ACTION_TAGS.BAD_ACTION) ||
        uaf.Tags.includes(USER_ACTION_TAGS.MISSED_OPPORTUNITY),
      [FEEDBACK_EVALUATIONS.FAIL]: (uaf: UserActionFeedback) =>
        uaf.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)
    };

    const filteredUserActionsFeedbacks = [];
    for (const uaf of iUserActionsFeedbacks) {
      if (!filterCriteria[iEvaluation](uaf)) {
        break;
      }
      filteredUserActionsFeedbacks.push(uaf);
    }

    return filteredUserActionsFeedbacks;
  }
}
