import React from 'react';
import log from 'loglevel';

export default class DbMeter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: window.sdk.videoconf().mediaDevices().getCurrentDeviceId(this.props.kind)
    };
    this.dbMeter = React.createRef();
    this.listeners = [];
  }

  componentDidMount() {
    this.listenVolume();
  }

  componentDidUpdate() {
    this.listenVolume();
  }

  componentWillUnmount() {
    for (const i in this.listeners) {
      try {
        this.listeners[i]();
      } catch (e) {
        log.error(e);
      }
    }
  }

  listenVolume = () => {
    let managerAudio = window.sdk.videoconf().mediaDevices().getAudioManager();
    if (!managerAudio) return;
    this.listeners.push(
      managerAudio.on('volume', (data) => {
        if (this.dbMeter.current)
          this.dbMeter.current.style.width = Math.round(5 * data * 100) + '%';
      })
    );
  };

  render() {
    return (
      <span className={`${this.props.className || ''} block h-5 w-full bg-speak/20 mask-dbmeter`}>
        <span ref={this.dbMeter} className="block h-full bg-speak"></span>
      </span>
    );
  }
}
