import React from 'react';
import '@/styles/components/AuthLayout.scss';
import Container from 'react-bootstrap/Container';
import { ReactComponent as Practicio } from '@/assets/new-ui/practicio.svg';
import video1 from '@/assets/videos/login-video1.mp4';
import video2 from '@/assets/videos/login-video2.mp4';
import video3 from '@/assets/videos/login-video3.mp4';
import DebugFtueUserProfile from '@/components/DebugFtueUserProfile/DebugFtueUserProfile';
import Loader from '@/components/Utilities/Loader';
import { Translation } from '@/components/Utilities/Translation';
import AuthRouting from '@/pages/Auth/AuthRouting';

class AuthLayout extends React.Component {
  state = {
    loading: false
  };

  listeners = [];

  componentDidMount() {
    this.listeners.push(
      window.sdk.event().on('fetchStarted', () => {
        this.setState({
          loading: true
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('fetchFinished', () => {
        this.setState({
          loading: false
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('loaderHide', () => {
        this.setState({
          loading: false
        });
      })
    );
  }

  // Supprime les events
  componentWillUnmount() {
    for (const i in this.listeners) {
      this.listeners[i]();
    }
  }

  

  render() {
    return (
      <div className="login-layout legacy">
        <div className={'debug debug_graph_controls'}>
          <DebugFtueUserProfile />
        </div>
        <Loader display={this.state.loading} />
        <div className="login__main">
          <Container>
            <div className="container mx-auto">
              <div className="flex flex-col items-center">
                <AuthRouting />
              </div>
            </div>
            
          </Container>
        </div>
      </div>
    );
  }
}

export default AuthLayout;
