import log from 'loglevel';
import * as Sentry from '@sentry/browser';
import { IExerciseGraph } from '@/AppClasses/ExerciseScenario/Interfaces/IExerciseGraph';

export default abstract class AbstractSolver {
  protected Logger = log;

  constructor(protected Graph: IExerciseGraph) {}

  protected HandleError(iErrorMessage: string): void {
    this.Logger.error(iErrorMessage);
    Sentry.captureMessage(iErrorMessage);
  }
}
