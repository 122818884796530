import log from 'loglevel';
import { ISessionHistory } from '../Interfaces/ISessionHistory';
import { IExerciseGraph } from '../Interfaces/IExerciseGraph';

export interface ISpeechPartsToHighlightData {
  userActionFeedbacksData: {
    [key: string]: {
      SourceUserActionsIDs: string[];
    };
  };
  composedUserActionsData: {
    [key: string]: {
      contributingActionIDs: string[];
    };
  };
  detectedUserActionsData: {
    userActionsIDs: string[];
    conversation: string;
  };
}

export default class SpeechPartsHighlight {
  static async CreateAndSaveSpeechPartsHighlights(
    iGraph: IExerciseGraph,
    iSessionHistory: ISessionHistory,
    iExerciseID: string,
    iBDDatabaseID: string,
    iSpeechPartsToHighlightData: ISpeechPartsToHighlightData
  ): Promise<void> {
    // Don't generate speech parts highlights if we are forcing the user actions (no relevant speech to highlight)
    if (window.testMode.forceUserActionsMode) {
      log.debug(
        'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: Not generating speech parts highlights because we are forcing the user actions.'
      );
      return;
    }

    // Make sure the graph is waiting for the end of this task before going to feedbacks page
    const taskID: string = iGraph.AddTaskToWaitBeforeEnd('CreateAndSaveSpeechPartsHighlights');

    // For each feedback, list the needed user actions to highlight in the user speech
    // userActionsToHighlight is a list of unique string IDs
    let userActionsToHighlight: string[] = [];
    for (let feedbackID of Object.keys(iSpeechPartsToHighlightData.userActionFeedbacksData)) {
      let userActionsIDs =
        iSpeechPartsToHighlightData.userActionFeedbacksData[feedbackID].SourceUserActionsIDs;
      for (let userActionID of userActionsIDs) {
        if (!userActionsToHighlight.includes(userActionID)) {
          userActionsToHighlight.push(userActionID);
        }
      }
    }

    // For each composed user action, list the needed user actions to highlight in the user speech
    for (let composedUserActionID of Object.keys(
      iSpeechPartsToHighlightData.composedUserActionsData
    )) {
      // If contributingUserActionID doesn't exist in the userActionsToHighlight list, ignore it
      if (!userActionsToHighlight.includes(composedUserActionID)) {
        log.debug(
          'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: Composed user action ' +
            composedUserActionID +
            ' was triggered, but it has not generated any user action feedback. So not needed to highlight parts.'
        );
        continue;
      }

      // Add the contributing user actions to the list of user actions to highlight
      let contributingUserActions =
        iSpeechPartsToHighlightData.composedUserActionsData[composedUserActionID]
          .contributingActionIDs;

      // If the composed user action has no contributing user actions (ex: default UA), ignore it
      if (!contributingUserActions) {
        log.debug(
          'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: Composed user action ' +
            composedUserActionID +
            ' has no contributing user actions. So not needed to highlight parts.'
        );
        continue;
      }

      for (let contributingUserActionID of contributingUserActions) {
        if (!userActionsToHighlight.includes(contributingUserActionID)) {
          userActionsToHighlight.push(contributingUserActionID);
        }
      }

      // Remove the composed user action from the list of user actions to highlight
      const index = userActionsToHighlight.indexOf(composedUserActionID);
      if (index > -1) {
        userActionsToHighlight.splice(index, 1);
      }
    }

    // Wait for the beautification of the speech parts to highlight
    // by gettin the history event and checking if beautifiedSpeech is filled
    let loopCounter = 0;
    const userSpeechEvent =
      iSessionHistory.GetUserSpeechByBranchingDecisionDatabaseID(iBDDatabaseID);
    if (!userSpeechEvent) {
      log.error(
        'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: No user speech event found in the history database. iBDDatabaseID = ' +
          iBDDatabaseID
      );
    }

    while (!userSpeechEvent.Content.BeautifiedSpeech) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      log.debug(
        'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: ' +
          taskID +
          ' Waiting for the beautified speech...'
      );
      loopCounter++;
      if (loopCounter > 60) {
        log.error(
          'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: Timeout while waiting for the beautified speech.'
        );
        break;
      }
    }

    // Get the speech to highlight, if beautifiedSpeech is not available, use the original speech
    let speech = userSpeechEvent.Content.BeautifiedSpeech
      ? userSpeechEvent.Content.BeautifiedSpeech
      : userSpeechEvent.Content.Speech;

    // Create speech parts highlights for each user action to highlight in the base User actions list
    let useractionsSpeechParts: { [key: string]: any[] } = {};

    // Map user actions to promises to process them in parallel
    const userActionPromises = userActionsToHighlight
      .filter((userActionID) =>
        iSpeechPartsToHighlightData.detectedUserActionsData.userActionsIDs.includes(userActionID)
      )
      .map(async (userActionID) => {
        let speechParts = await this.GetSpeechParts(
          speech,
          iSpeechPartsToHighlightData.detectedUserActionsData.conversation,
          userActionID,
          iExerciseID
        );

        if (speechParts && speechParts.length > 0) {
          return { userActionID, speechParts };
        }

        // In case no speech parts are found
        log.error(
          'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: No speech parts found for user action ' +
            userActionID
        );

        return { userActionID, speechParts: [] };
      });

    // Wait for all promises to resolve
    const results = await Promise.all(userActionPromises);

    // Assign the results to the useractionsSpeechParts object
    results.forEach((result) => {
      if (result) {
        useractionsSpeechParts[result.userActionID] = result.speechParts;
      }
    });

    // Project the speech parts from base user actions to the composed user actions and save the result in useractionsSpeechParts
    for (let userActionID of Object.keys(iSpeechPartsToHighlightData.composedUserActionsData)) {
      let contributingUserActions =
        iSpeechPartsToHighlightData.composedUserActionsData[userActionID].contributingActionIDs;

      if (!contributingUserActions) {
        log.debug(
          'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: Composed user action ' +
            userActionID +
            ' has no contributing user actions. So not needed to highlight parts.'
        );
        continue;
      }

      let speechParts: any[] = [];
      for (let contributingUserActionID of contributingUserActions) {
        if (useractionsSpeechParts[contributingUserActionID]) {
          speechParts.push(useractionsSpeechParts[contributingUserActionID]);
        }
      }
      useractionsSpeechParts[userActionID] = speechParts;
    }

    // project the speech parts from all (base and composed) user actions to the user action feedbacks
    let userActionsFeedbacksSpeechParts: { [key: string]: any[] } = {};
    for (let feedbackID of Object.keys(iSpeechPartsToHighlightData.userActionFeedbacksData)) {
      let sourceUserActionsIDs =
        iSpeechPartsToHighlightData.userActionFeedbacksData[feedbackID].SourceUserActionsIDs;
      let speechParts: any[] = [];
      for (let userActionID of sourceUserActionsIDs) {
        if (!useractionsSpeechParts[userActionID]) {
          log.error(
            'SpeechPartsHighlight.CreateAndSaveSpeechPartsHighlights: No speech parts found for user action ' +
              userActionID
          );
          continue;
        }

        // For each speech part in useractionsSpeechParts
        for (let speechPart of useractionsSpeechParts[userActionID]) {
          speechParts.push({
            SourceUserAction: userActionID,
            PartText: speechPart
          });
        }
      }
      userActionsFeedbacksSpeechParts[feedbackID] = speechParts;
    }

    // Save the speech parts highlights to the user actions feedbacks events in the history database (update elements)
    const userActionsFeedbacksSpeechPartsPromises = Object.keys(
      userActionsFeedbacksSpeechParts
    ).map(async (feedbackID) => {
      iSessionHistory.AddSpeechPartsToUserActionFeedback(
        iBDDatabaseID,
        feedbackID,
        userActionsFeedbacksSpeechParts[feedbackID]
      );
    });
    await Promise.all(userActionsFeedbacksSpeechPartsPromises);

    // Release the graph on that task
    iGraph.OnTaskEnded(taskID);
  }

  static async GetSpeechParts(
    iSpeech: string,
    iConversation: string,
    iUserActionID: string,
    iExerciseId: string
  ): Promise<any[] | null> {
    try {
      const gptAnswer = await window.sdk.fetchInternalAPI().post('/llm/speech-highlight', {
        body: {
          speech: iSpeech,
          conversation: iConversation,
          userActionID: iUserActionID,
          exerciseID: iExerciseId,
          exerciseSessionID: window.sdk.exerciseSessionID
        }
      });

      return gptAnswer.speechPartsToHighlight;
    } catch (error) {
      log.error(`SpeechPartsHighlight.GetSpeechParts: ${error}`);
    }

    return null;
  }

  // Return the speech with the parts highlighted in HTML (bold and green)
  static HighlightSpeechFromParts(
    iSpeech: string,
    iSpeechParts: { PartText: string }[],
    iStyleColor: string = 'green'
  ): string {
    if (!iSpeechParts) {
      return iSpeech;
    }

    let speech = iSpeech;
    iSpeechParts.forEach((speechPart) => {
      speech = speech.replace(
        speechPart.PartText,
        `<span style="color:${iStyleColor}; font-weight:bold;">${speechPart.PartText}</span>`
      );
    });

    return speech;
  }
}
