import React from 'react';
import log from 'loglevel';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Translation } from '@/components/Utilities/Translation';
import { ReactComponent as Practicio } from '@/assets/new-ui/practicio.svg';
import { useTranslate } from '@tolgee/react';

function PasswordLost() {
  const { t } = useTranslate();
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState(location.state?.email || '');
  const [status, setStatus] = React.useState('');
  const [info, setInfo] = React.useState({});

  const submit = async () => {
    let res = await window.sdk.user().forgotPassword({
      email: email
    });

    log.debug('res', res);

    if (res.state === 'fail') {
      log.debug('res', res);
      setStatus('fail');
      setInfo(res.info);
    } else {
      setStatus('success');
      setInfo(res.info);
      navigate('/edit-password', false);
    }
  };

  const updateEmail = (value) => {
    setEmail(value);
    setStatus('');
  };

  const showZendesk = () => {
    if (window.zE) {
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'show');
    } else {
      window.location = 'mailto:support@practicio.fr';
    }
  };

  return (
    <>
      <Practicio className="mb-6 h-auto w-52 max-w-full fill-accent" />
      <h2 className="text-center title sm:w-3/4 lg:w-1/4">
        <Translation keyName="passwordLost.email">
          Recevez par email un code de renouvellement de mot de passe
        </Translation>{' '}
      </h2>
      <form
        className="mt-4 flex w-full flex-col items-center sm:w-3/4 lg:w-1/4"
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        {status === 'fail' && (
          <Alert className="alert mt-4" variant="danger">
            {info.message}
          </Alert>
        )}

        <Form.Group controlId="email mt-4" className="w-full">
          <Form.Label className="pl-4 label">
            <Translation keyName="general.email">Email</Translation>
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            autoComplete="email"
            placeholder={t('login.your_email', 'Votre adresse email')}
            onChange={(e) => updateEmail(e.target.value.toLowerCase())}
            value={email}
            required
          />
        </Form.Group>

        {status === 'success' && (
          <Alert className="alert mt-4" variant="success">
            {info.message}
          </Alert>
        )}

        <button
          className="cta cta--accent mt-4 self-start"
          onClick={submit}
          data-testid="password-lost-submit-btn">
          <Translation keyName="general.send">Envoyer</Translation>
        </button>

        <p className="mt-4 self-start">
          <LinkContainer to="/">
            <a className="link text-xs">
              <Translation keyName="general.login">Connexion</Translation>
            </a>
          </LinkContainer>
        </p>
        <p className="mt-2 self-start text-xs">
          <button className="link" onClick={showZendesk}>
            <Translation keyName="general.support">Contacter le support</Translation>
          </button>{' '}
          -{' '}
          <a href={window.policyUri} className="link" target="_blank" rel="noreferrer">
            <Translation keyName="general.privacy">Politique de confidentialité</Translation>
          </a>{' '}
          <span className="text-brand/50">- Practicio {window.infoVersion.version}</span>
        </p>
      </form>
    </>
  );
}

export default PasswordLost;
