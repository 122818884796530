import AbstractSolver from '../AbstractSolver';
import { USER_ACTION_TAGS, FEEDBACK_EVALUATIONS } from '../constants';
import { uniqBy } from '../../../Utils/uniqBy';
import { HistoryEventTypes } from '@/AppClasses/ExerciseScenario/ExerciseSessionHistory';

const DISPLAYED_RECOMMENDED_UA_NUMBER = 2;
const DISPLAYED_UA_FEEDBACK_NUMBER = 3;
const DISPLAYED_UA_IMPROVMENT_AXE_NUMBER = 3;

export default class DetailedFeedbacksSolver extends AbstractSolver {
  /**
   * Solves detailed feedbacks for all acts
   * @returns {Array} Arrays of solved act feedbacks and improvement axes
   */
  async SolveDetailedFeedbacks() {
    const actCompletionEvents = this.Graph.History.GetAllEventsBy({
      EventType: HistoryEventTypes.ACT_COMPLETION
    });
    const uniqActCompletionEvents = uniqBy(actCompletionEvents, (event) => event.Content.NodeID);
    const actNodes = this.Graph.GetNodesByType('Act');

    const solvedFeedbacksPromises = [];
    for (const actCompletionEvent of uniqActCompletionEvents) {
      solvedFeedbacksPromises.push(this.SolveActFeedback(actCompletionEvent));
    }

    const solvedFeedbacks = await Promise.all(solvedFeedbacksPromises);
    const undiscoveredActs = this.GetUndiscoveredActs(uniqActCompletionEvents, actNodes);
    const solvedActsFeedbacks = [...solvedFeedbacks, ...undiscoveredActs];

    return {
      solvedActsFeedbacks,
      improvementAxes: this.ComputeImprovementAxes(solvedActsFeedbacks)
    };
  }

  /**
   * Computes improvement axes based on the /user actions feedback for each act
   * @param {Array} iFeedbacksByActs - Array of feedback objects for each act
   * @returns {Array} Array of improvement axes, limited to a maximum of 3 items
   */
  ComputeImprovementAxes(iFeedbacksByActs) {
    const improvementAxes = [];

    iFeedbacksByActs.forEach((act) => {
      act.userActions?.forEach((userAction) => {
        if (
          userAction.evaluation === FEEDBACK_EVALUATIONS.BAD ||
          userAction.evaluation === FEEDBACK_EVALUATIONS.FAIL
        ) {
          improvementAxes.push({
            index: userAction.id,
            kind: 'WARNING',
            label: userAction.displayedName
          });
        }
      });
    });

    const uniqImprovementAxes = uniqBy(improvementAxes, 'index');
    return uniqImprovementAxes.slice(0, DISPLAYED_UA_IMPROVMENT_AXE_NUMBER);
  }

  /**
   * Gets the evaluation result for a specific act from the act completion events
   * @param {string} iActName - Name of the act to get evaluation for
   * @param {Array} iActCompletionEvents - Array of act completion events from history
   * @returns {string|null} The evaluation result for the act (e.g. 'GOOD', 'BAD', 'FAIL') or null if not found
   */
  GetActCompletionEvaluation(iActName, iActCompletionEvents) {
    const evaluationEvent = iActCompletionEvents.find((event) => {
      return event.Content.ActName === iActName;
    });

    return evaluationEvent?.Content.Evaluation || null;
  }

  /**
   * Gets not discovered acts
   * @param {Array} iActCompletionEvents - array of act completion events
   * @param {Array} iActNodes - array of act nodes
   * @returns {Array} Array of not discovered acts
   */
  GetUndiscoveredActs(iActCompletionEvents, iActNodes) {
    const undiscoveredActs = iActNodes
      .filter(
        (actNode) =>
          !iActCompletionEvents.find((event) => event.Content.ActName === actNode.ActName)
      )
      .sort((a, b) => a.ActNumber - b.ActNumber)
      .map((actNode) => ({
        id: actNode.ActName,
        displayedName: actNode.ActName,
        isUndiscovered: true,
        userActions: []
      }));

    // we need to filter on act displayedName unicity
    // because we can have multiple act nodes with the same displayedName
    return uniqBy(undiscoveredActs, 'displayedName');
  }

  /**
   * Solves feedbacks for a single act
   * @param {Object} iActCompletionEvent - act completion event
   * @returns {Object} Solved act feedback
   */
  async SolveActFeedback(iActCompletionEvent) {
    const userActionsFeedbacksToDisplay = [];

    for (const uaf of iActCompletionEvent.Content.UserActionsFeedbacks) {
      userActionsFeedbacksToDisplay.push(await this.SolveOneUserActionFeedback(uaf));
    }

    return {
      id: iActCompletionEvent.Content.ActName,
      displayedName: iActCompletionEvent.Content.ActName,
      userActions: userActionsFeedbacksToDisplay.slice(0, DISPLAYED_UA_FEEDBACK_NUMBER),
      evaluation: iActCompletionEvent.Content.Evaluation
    };
  }

  /**
   * Solves feedback for a single user action
   * @param {Object} iUserActionFeedback - User action feedback
   * @returns {Object} Solved user action feedback
   */
  async SolveOneUserActionFeedback(iUserActionFeedback) {
    const userActionFeedbackInfo = this.Graph.GetFullUserActionFeedbackData(
      iUserActionFeedback.ID,
      iUserActionFeedback.BranchingDecisionNodeID
    );

    const userActionFeedbackEvent = this.Graph.History.GetOneEventBy({
      EventType: HistoryEventTypes.USER_ACTION_FEEDBACK,
      'Content.BranchingDecisionDatabaseID': iUserActionFeedback.BranchingDecisionDatabaseID,
      'Content.UserActionFeedbackID': iUserActionFeedback.ID
    });

    const userSpeechEvent = this.Graph.History.GetOneEventBy({
      EventType: HistoryEventTypes.USER_SPEECH,
      'Content.BranchingDecisionDatabaseID': iUserActionFeedback.BranchingDecisionDatabaseID
    });

    const sceneActivationEvent = this.Graph.History.GetOneEventBy({
      EventType: HistoryEventTypes.SCENE_ACTIVATION,
      'Content.NodeID': iUserActionFeedback.SceneNodeID
    });

    const userSpeech =
      userSpeechEvent.Content.BeautifiedSpeech || userSpeechEvent.Content.Speech || '';

    const userActionFeedbackEvaluation = this.EvaluateUserActionFeedback(iUserActionFeedback);

    return {
      id: iUserActionFeedback.ID,
      displayedName: userActionFeedbackInfo.DisplayedName,
      tags: iUserActionFeedback.Tags,
      priorityRank: iUserActionFeedback.PriorityRank,
      rerunNodeID: sceneActivationEvent.Content.NodeID,
      userSpeech: userSpeech,
      speechParts: userActionFeedbackEvent.Content.SpeechParts || [],
      botSpeech: await this.GetBotSpeech(userActionFeedbackEvent),
      detailsText: userActionFeedbackInfo.DetailsText,
      evaluation: userActionFeedbackEvaluation,
      recommendations:
        userActionFeedbackEvaluation !== FEEDBACK_EVALUATIONS.GOOD
          ? this.GetFeedbackRecommendations(iUserActionFeedback.BranchingDecisionNodeID)
          : [
              {
                id: userActionFeedbackInfo.ID,
                description: userActionFeedbackInfo.Description,
                displayedName: userActionFeedbackInfo.DisplayedName
              }
            ]
    };
  }

  /**
   * Retrieves the bot speech associated with a user action feedback event
   * @param {Object} iUserActionFeedbackEvent - The user action feedback event
   * @returns {string} The bot speech transcript or an empty string if not found
   */
  async GetBotSpeech(iUserActionFeedbackEvent) {
    const videoEventBeforeFeedback = this.Graph.History.GetVideoEventBeforeUserActionFeedback(
      iUserActionFeedbackEvent.Content.NodeID
    );

    const videoInfos = await window.sdk.BotVideo().getOne(videoEventBeforeFeedback.Content.Video);

    return {
      transcript: videoInfos.transcript || '',
      botName: videoEventBeforeFeedback.Content.Character
    };
  }

  /**
   * Evaluates a single user action feedback
   * @param {Object} iUserActionFeedback - User action feedback
   * @returns {string} Evaluation result (GOOD, BAD or FAIL)
   */
  EvaluateUserActionFeedback(iUserActionFeedback) {
    if (iUserActionFeedback.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    return iUserActionFeedback.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION)
      ? FEEDBACK_EVALUATIONS.GOOD
      : FEEDBACK_EVALUATIONS.BAD;
  }

  /**
   * Retrieves feedback recommendations for a given branching decision node
   * /!\ for now, we do not handle the special case for "Diamond" trophy
   * @param {string} iBranchingDecisionNodeID - The ID of the branching decision node
   * @returns {Array} An array of feedback recommendations, each containing id, displayedName, and description
   */
  GetFeedbackRecommendations(iBranchingDecisionNodeID) {
    const branchingDecisionNode = this.Graph.GetNode(iBranchingDecisionNodeID);

    const userActionFeedbacks = Object.values(
      branchingDecisionNode.AvailableUserActionsFeedbacks
    ).map((uaf) => this.Graph.GetFullUserActionFeedbackData(uaf.ID, branchingDecisionNode.ID));

    const filteredUserActionsFeedbacks = userActionFeedbacks
      .filter((uaf) => uaf.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION))
      .sort((a, b) => b.PriorityRank - a.PriorityRank)
      .splice(0, DISPLAYED_RECOMMENDED_UA_NUMBER);

    return filteredUserActionsFeedbacks.map((uaf) => ({
      id: uaf.ID,
      displayedName: uaf.DisplayedName,
      description: uaf.Description
    }));
  }
}
