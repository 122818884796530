import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import { EvaluationType } from '@/models/DetailedFeedbackModel';

import goodIcon from '@/assets/icons/tooltip/good.svg';
import badIcon from '@/assets/icons/tooltip/bad.svg';
import failIcon from '@/assets/icons/tooltip/fail.svg';

const DISPLAY_DURATION = 4750;
const FADING_DURATION = 400;

export type OriginSideType = 'TOP' | 'RIGHT' | 'BOTTOM' | 'LEFT';
export type TranslateYType = 'BOTTOM_TO_TOP' | 'BOTTOM_TO_BOTTOM' | 'TOP_TO_BOTTOM' | 'TOP_TO_TOP';

const TO_TOP = '-translate-y-10';
const TO_BOTTOM = 'translate-y-10';

type ToastProps = {
  className?: string;
  id?: string;
  evaluation?: EvaluationType;
  originSide?: OriginSideType;
  translateY?: TranslateYType;
  onClose: () => void;
};

const TRANSLATE_Y_FADING_CLASSNAMES: Record<TranslateYType, { in: string; out: string }> = {
  BOTTOM_TO_TOP: {
    in: TO_BOTTOM,
    out: TO_TOP
  },
  BOTTOM_TO_BOTTOM: {
    in: TO_BOTTOM,
    out: TO_BOTTOM
  },
  TOP_TO_BOTTOM: {
    in: TO_TOP,
    out: TO_BOTTOM
  },
  TOP_TO_TOP: {
    in: TO_TOP,
    out: TO_TOP
  }
};

const BASE_ARROW_CLASS =
  'before:absolute before:h-2 before:w-2 before:-translate-x-1/2 before:rotate-45 before:rounded-[1px] before:bg-[var(--evaluation)]';

const ARROW_CLASSES: Record<OriginSideType, string> = {
  TOP: `${BASE_ARROW_CLASS} before:bottom-full before:left-1/2 before:mt-[1px] before:translate-y-1/2`,
  RIGHT: `${BASE_ARROW_CLASS} before:left-full before:top-1/2 before:-ml-[1px] before:-translate-y-1/2`,
  BOTTOM: `${BASE_ARROW_CLASS} before:left-1/2 before:top-full before:-mt-[1px] before:-translate-y-1/2`,
  LEFT: `${BASE_ARROW_CLASS} before:left-0 before:top-1/2 before:ml-[1px] before:-translate-y-1/2`
};

const Toast = ({
  className = '',
  id,
  evaluation,
  originSide,
  translateY,
  onClose,
  children
}: PropsWithChildren<ToastProps>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const onCloseRef = useRef(onClose);

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  const [contentWidth, setContentWidth] = useState<string>();
  const [contentMaxHeight, setContentMaxHeight] = useState<string>();
  const [isFadingOut, setIsFadingOut]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);

  const [isFadingIn, setIsFadingIn]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(true);

  const arrowClassName: string = originSide ? ARROW_CLASSES[originSide] : '';

  const translateYFadingClassName: { in: string; out: string } = translateY
    ? TRANSLATE_Y_FADING_CLASSNAMES[translateY]
    : { in: '', out: '' };

  const getEvaluationIcon = (evaluation?: EvaluationType) => {
    switch (evaluation) {
      case 'BAD':
        return badIcon;
      case 'FAIL':
        return failIcon;
      case 'GOOD':
      default:
        return goodIcon;
    }
  };

  const getToastStyles = (evaluation?: EvaluationType): React.CSSProperties | undefined => {
    if (!evaluation) return undefined;
    return {
      '--evaluation': `var(--${evaluation.toLowerCase()}-color)`
    } as React.CSSProperties;
  };

  const getToastClassNames = () => {
    const baseClasses =
      'relative flex w-fit items-center whitespace-nowrap rounded-2lg bg-[var(--evaluation)] py-2.5 pl-3 pr-4 text-sm font-semibold text-black/70';
    const fadeClasses = isFadingOut || isFadingIn ? 'opacity-0' : 'opacity-100';
    const translateInClasses =
      translateY && isFadingIn ? `${translateYFadingClassName.in} ease-in` : '';
    const translateOutClasses =
      translateY && isFadingOut ? `${translateYFadingClassName.out} ease-out` : '';

    return `${baseClasses} ${arrowClassName} transition-all duration-300 ${fadeClasses} ${translateInClasses} ${translateOutClasses}`;
  };

  useEffect(() => {
    const fadeInTimer = setTimeout(() => {
      setIsFadingIn(false);
      if (contentRef.current) {
        setContentMaxHeight(contentRef.current.offsetHeight + 'px');
        setContentWidth(contentRef.current.offsetWidth + 'px');
      }
    }, FADING_DURATION);

    return () => clearTimeout(fadeInTimer);
  }, []);

  useEffect(() => {
    const displayTimer = setTimeout(() => {
      setIsFadingOut(true);
    }, DISPLAY_DURATION - FADING_DURATION);

    const fadeOutTimer = setTimeout(() => {
      onCloseRef.current();
    }, DISPLAY_DURATION);

    return () => {
      clearTimeout(displayTimer);
      clearTimeout(fadeOutTimer);
    };
  }, []);

  return (
    <div
      style={{ maxHeight: contentMaxHeight }}
      className={`${className} max-h-0 transition-all duration-300`}>
      <div ref={contentRef} style={{ width: contentWidth }} className="p-1">
        <p
          data-testid={`user-action-toast-${id}-${evaluation}`}
          style={getToastStyles(evaluation)}
          className={getToastClassNames()}>
          <img className="mr-2" src={getEvaluationIcon(evaluation)} alt={evaluation || 'GOOD'} />
          <span className="pr-4">{children}</span>
        </p>
      </div>
    </div>
  );
};

export default Toast;
