export enum ValidOperator {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT'
}

export type Rule = {
  condition: Condition;
  result: string | string[];
};

export type Condition =
  | string
  | {
      [ValidOperator.AND]?: Condition[];
      [ValidOperator.OR]?: Condition[];
      [ValidOperator.NOT]?: Condition;
      [key: string]: Condition | Condition[] | undefined;
    };

export type FoundUserAction = {
  contributingActionIDs: string[];
};

export type FoundUserActions = {
  [key: string]: FoundUserAction;
};
