import log from 'loglevel';
import Utils from '../Utils/Utils';
import AbstractParticipant from './AbstractParticipant';
import ParticipantsModule from './ParticipantsModule';
import Video from './Video';

export default class Bot extends AbstractParticipant {
  // Parameters
  Gender = '';

  DefaultLoopVideoName = '';

  // Video player and UI elements
  VideoSlot = null;
  m_VideoPlayer1;
  m_VideoPlayer2;
  m_UserNameUIElement;

  // Current action
  m_NodeToKeepUpdated = null;
  m_CurrentVideoName = '';

  transitionTimeDelay = 600;

  currentPlayer = 0;
  oldCurrentPlayer = 0;

  currentVideo;
  currentVideoWaitingLoop;
  builded = false;
  isPlayingVideo = false;

  // Subtitles
  subtitles = '';
  onSubtitlesChange = null; // Callback

  m_domIsReady = false;

  constructor(iBotName, iBotGender, iLoopVideoName) {
    super();
    this.ID = 'Bot_' + iBotName + '_' + this.ID;

    log.debug('Bot created: ' + iBotName);
    this.Name = iBotName;
    this.Gender = iBotGender;
    this.SetWaitingLoop(iLoopVideoName);

    //this.setConnectionState('connecting');

    this.oldPlayerTimeout = {};

    //this.CreateVideoPlayer();

    // Write state debug values
    if (window.testMode.fillAppStateValues) {
      if (!window.testMode.appStateValues['bots']) {
        window.testMode.appStateValues['bots'] = {};
      }
      window.testMode.appStateValues.bots[this.Name] = {
        ID: this.ID,
        CurrentVideo: ''
      };
    }
  }

  Stop() {
    log.debug(
      "Bot '" + this.Name + "' stopping...",
      this.currentVideo,
      this.currentVideoWaitingLoop
    );
    if (this.currentVideo) this.currentVideo.delete();
    if (this.currentVideoWaitingLoop) this.currentVideoWaitingLoop.delete();

    // Write state debug values
    if (window.testMode.fillAppStateValues) delete window.testMode.appStateValues.bots[this.Name];
  }

  Pause() {
    log.debug('currentVideo', this.currentVideo, 'pause');
    if (this.currentVideo) this.currentVideo.pause();
  }

  Resume() {
    if (this.currentVideo) this.currentVideo.play();
  }

  domIsReady(parentElement) {
    log.debug("Bot '" + this.Name + "' domIsReady...");

    this.m_VideoPlayer1 = parentElement.querySelector('.bot_layer1');
    this.m_VideoPlayer2 = parentElement.querySelector('.bot_layer2');

    this.m_domIsReady = true;
    // window.addEventListener( 'resize', this.onWindowResize );
  }

  async isDomReady() {
    while (!this.m_domIsReady) {
      await Utils.Sleep(100);
    }
  }

  async createVideo(iVideoName, loop = false) {
    let videoURL = ParticipantsModule.Instance.CreateBotVideoURL(
      this.Name,
      iVideoName,
      window.sdk.ScenarioLanguage
    );
    let video = new Video(videoURL, {
      transitionTimeDelay: this.transitionTimeDelay / 2,
      loop,
      iVideoName
    });

    await video.isReady();
    await this.isDomReady();

    return video;
  }

  async SetWaitingLoop(DefaultLoopVideoName) {
    this.DefaultLoopVideoName = DefaultLoopVideoName;
    this.currentVideoWaitingLoop = await this.createVideo(this.DefaultLoopVideoName, true);
    if (!this.isPlayingVideo) {
      //this.PlaySmoothly(this.currentVideoWaitingLoop, 'WaitingLoop');
    }
  }

  SetSubtitles(iSubtitles) {
    this.subtitles = iSubtitles;
    if (this.onSubtitlesChange) {
      this.onSubtitlesChange(iSubtitles);
    }
  }

  subscribeToSubtitlesChange(callback) {
    this.onSubtitlesChange = callback;
  }

  async PlayVideo(iVideoName, opt = {}) {
    log.debug("Bot '" + this.Name + "' playing video '" + iVideoName + "'...");

    // Write state debug values
    if (window.testMode.fillAppStateValues)
      window.testMode.appStateValues.bots[this.Name]['CurrentVideo'] = iVideoName;

    if (this.isPlayingVideo) {
      log.debug('A video is alreay playing');
      if (opt.wait) {
        while (this.isPlayingVideo) {
          await Utils.Sleep(100);
        }
      } else {
        log.debug("Bot '" + this.Name + "' Bypass the current video");
      }
    }

    this.isPlayingVideo = true;

    log.debug("Bot '" + this.Name + "' assign ", iVideoName);
    this.m_CurrentVideoName = iVideoName;
    let defaultCallback = () => {};

    let onTimeTriggered = opt.onTimeTriggered || defaultCallback;
    let onEnded = opt.onEnded || defaultCallback;

    let lastCurrentVideo;
    if (this.currentVideo) lastCurrentVideo = this.currentVideo;

    this.currentVideo = await this.createVideo(iVideoName);

    if (lastCurrentVideo) {
      lastCurrentVideo.stopVideoEndedListener();
      setTimeout(() => {
        if (lastCurrentVideo) {
          //log.debug("Bot '" + this.Name + "' we remove last current video", lastCurrentVideo.getSrc());
          lastCurrentVideo.remove();
        }
      }, this.transitionTimeDelay * 4);
    }

    // force the speaking state to "speaking", to fix the bug of the 2nd video that doesn't has the speaking state
    this.setSpeakingState('speaking');
    await this.PlaySmoothly(this.currentVideo, 'Video', opt.triggerTime, onTimeTriggered, onEnded);
  }

  async ReturnToWaitLoop() {
    log.debug("Bot '" + this.Name + "' returning to wait loop...");

    if (!this.currentVideoWaitingLoop) {
      log.error("Bot '" + this.Name + "' no wait loop video!");
    } else {
      await this.PlaySmoothly(this.currentVideoWaitingLoop, 'WaitingLoop');
    }
  }

  OnTimeTriggered(type, video, callback) {
    if (type !== 'Video') return;

    log.debug(
      "Bot.OnTimeTriggered: Bot '" +
        this.Name +
        "' time triggered video '" +
        this.m_CurrentVideoName +
        "'."
    );

    if (callback) {
      callback();
    } else {
      log.debug(
        "Bot.OnTimeTriggered: Bot '" +
          this.Name +
          "' no callback for video '" +
          this.m_CurrentVideoName +
          "'."
      );
    }
  }

  OnVideoEnded(type, video, onEnded) {
    if (type !== 'Video') return;

    log.debug(
      "Bot.OnVideoEnded: Bot '" +
        this.Name +
        "' finished playing video '" +
        this.m_CurrentVideoName +
        "'. Starting video '" +
        this.DefaultLoopVideoName +
        "'.",
      video.noEnd
    );
    this.m_CurrentVideoName = this.DefaultLoopVideoName;

    // Write state debug values
    if (window.testMode.fillAppStateValues)
      window.testMode.appStateValues.bots[this.Name]['CurrentVideo'] = this.DefaultLoopVideoName;

    this.isPlayingVideo = false;
    let videoTmp = this.currentVideo;
    this.currentVideo = null;

    // Alert the command sender (graph node) that the video has ended

    log.debug("Bot '" + this.Name + "' log onEnded", this.onEnded);

    if (onEnded) {
      log.debug("Bot.OnVideoEnded: Bot '" + this.Name + "' we player onEnded of ", video.getSrc());
      onEnded();
      //log.debug('ON ENNNNNNNNNNNNNNNNNNNNNNNNNNNNED DELETE', video.getSrc());
      //this.onEnded = null;
    }

    // Start the waiting loop video

    this.PlaySmoothly(this.currentVideoWaitingLoop);
    if (type === 'Video') {
      setTimeout(() => {
        if (videoTmp) {
          log.debug('we remove old video', videoTmp.getSrc());
          videoTmp.remove();
        }
      }, this.transitionTimeDelay * 4);
    }
  }

  async PlaySmoothly(video, type, triggerTime, onTimeTriggered, onEnded) {
    this.currentPlayer++;
    if (this.currentPlayer > 2) this.currentPlayer = 1;

    if (this.oldPlayerTimeout[this.currentPlayer]) {
      //log.debug('clearTimeout');
      clearTimeout(this.oldPlayerTimeout[this.currentPlayer]);
    }

    //log.debug('playScenario', this.currentPlayer)

    video.initializeVideo(
      {
        duration: 'full',
        triggerTime: triggerTime
      },
      () => {
        log.debug("Bot '" + this.Name + "' video reached time trigger: ", video.getSrc());
        this.OnTimeTriggered(type, video, onTimeTriggered);
      },
      () => {
        log.debug("Bot '" + this.Name + "' video reached the end: ", video.getSrc());
        this.OnVideoEnded(type, video, onEnded);
      }
    );
    await video.play();
    let player = video.getPlayer();

    if (type === 'Video') {
      if (this.currentVideo) this.currentVideo.noEnd = true;
      this.currentVideo = video;
      this.currentVideo.noEnd = false;
    }

    //log.debug('currentPlayer', this.currentPlayer)

    if (this.oldCurrentPlayer) {
      this['m_VideoPlayer' + this.oldCurrentPlayer].style.zIndex = 1;
    }
    if (
      this['m_VideoPlayer' + this.currentPlayer] &&
      this['m_VideoPlayer' + this.currentPlayer].innerHTML !== ''
    ) {
      this['m_VideoPlayer' + this.currentPlayer].innerHTML = '';
    }
    this['m_VideoPlayer' + this.currentPlayer].appendChild(player);
    this['m_VideoPlayer' + this.currentPlayer].style.zIndex = 2;
    this['m_VideoPlayer' + this.currentPlayer].style.opacity = 1;
    // this.onWindowResize();

    if (this.oldCurrentPlayer !== 0) {
      this.oldNumero = this.currentPlayer === 1 ? 2 : 1;
      this.hideOldPlayer(this.oldCurrentPlayer, this.oldNumero);
    }

    this.oldCurrentPlayer = this.currentPlayer;
  }

  hideOldPlayer(oldPlayer, numero) {
    this.oldPlayerTimeout[numero] = setTimeout(() => {
      this['m_VideoPlayer' + oldPlayer].style.opacity = 0;
      this['m_VideoPlayer' + oldPlayer].innerHTML = '';
    }, this.transitionTimeDelay);
  }
}
