import { sanitizeHTML } from '@/helpers/sanitizeHTML';
import { Translation } from '../Utilities/Translation';
import Card from '@UIKit/Card/Card';
import React from 'react';

type Recommendation = {
  DisplayedName: string;
  Description: string;
  DetailsText: string;
};

type RecommendationCardProps = {
  recommendations: Recommendation[];
  toggleModal: (name: string, details: string) => void;
};

export const RecommendationCard = ({ recommendations, toggleModal }: RecommendationCardProps) => {
  return (
    <Card
      className="mt-6 p-8"
      areCustomStyles={false}
      dataTestid="exercise-briefing-recommendation">
      <Card.Border color="advice">
        <Card.Title
          color="advice"
          label={
            <Translation keyName="briefing.recommendationText">
              Pour votre prochaine interaction, nous vous suggérons
            </Translation>
          }>
          {recommendations[0].DisplayedName}
        </Card.Title>
        <div className="flex items-center justify-between">
          <div className="flex items-start">
            <div
              className="flex-1"
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(recommendations[0].Description)
              }}
            />
            <button
              className="cta cta--accent ml-8 whitespace-nowrap"
              onClick={() =>
                toggleModal(recommendations[0].DisplayedName, recommendations[0].DetailsText)
              }>
              En savoir plus
            </button>
          </div>
        </div>
      </Card.Border>
    </Card>
  );
};
